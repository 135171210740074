import { Col, Row, Table } from "antd";
import { useEffect, useState, useRef } from "react";
import { ColumnsType } from "antd/es/table";
import MasTeamReportService from "../../../../services/MasTeamReportService";
import dayjs from "dayjs";
import ReusableChart from "../../CommonReport/SummaryReport/ReusableChart";

const MasTmAutoAndPreAutoPilotReport = ({
  dateRange,
  periodCount,
}: {
  dateRange: any;
  periodCount: number;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [dateLabels, setDateLabels] = useState<any[]>([]);
  const [chartSeries, setChartSeries] = useState<any[]>([]);
  const [chartHeight, setChartHeight] = useState<number>(200);
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1200 && tableRef.current) {
        const tableColHeight = tableRef.current.getBoundingClientRect().height;
        setChartHeight(Math.max(200, tableColHeight - 30));
      } else {
        setChartHeight(250);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, [dataSource]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const periods = [];
      let start = dayjs(dateRange[0]);
      let end = dayjs(dateRange[1]);

      for (let i = 0; i < periodCount; i++) {
        const formattedStart = start.format("YYYY-MM-DDT00:00:00");
        const formattedEnd = end.format("YYYY-MM-DDT23:59:59");

        periods.push({
          start: formattedStart,
          end: formattedEnd,
          label: `${start.format("DD.MMM.YY")} - ${end.format("DD.MMM.YY")}`,
        });

        const diffDays = end.diff(start, "days") + 1;
        end = start.subtract(1, "days");
        start = end.subtract(diffDays - 1, "days");
      }

      const reversedPeriods = periods.reverse();
      getReports(reversedPeriods);
      setDateLabels(reversedPeriods);
    }
    // eslint-disable-next-line
  }, [dateRange, periodCount]);

  const getReports = async (periods: any[]) => {
    setLoading(true);

    try {
      const promises = periods.map((period) =>
        MasTeamReportService.getAutoAndPreAutoPilotReport(
          period.start,
          period.end
        )
      );

      const responses = await Promise.all(promises);
      const combinedData = periods.map((p, i) => ({
        ...p,
        ...responses[i],
      }));

      setDataSource(combinedData);

      const newChartSeries = [
        {
          name: "Active Users in Auto Pilot",
          data: combinedData.map((d) => d.activeUsersInAutoPilot || 0),
        },
        {
          name: "Active Users in Pre-Auto Pilot",
          data: combinedData.map((d) => d.activeUsersInPreAutoPilot || 0),
        },
        {
          name: "Suspend Data in Auto Pilot",
          data: combinedData.map((d) => d.suspendDataInAutoPilot || 0),
        },
        {
          name: "Transitioned Count",
          data: combinedData.map((d) => d.transitionedCount || 0),
        },
        {
          name: "Not Transitioned Count",
          data: combinedData.map((d) => d.notTransitionedCount || 0),
        },
      ];

      setChartSeries(newChartSeries);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: "Period",
      dataIndex: "label",
      key: "label",
      align: "center",
      width: 180,
      fixed: "left",
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) =>
        new Date(a.label).getTime() - new Date(b.label).getTime(),
      render: (label: string) => <b>{label ? label : "-"}</b>,
    },
    {
      title: "Active Users in Auto Pilot",
      dataIndex: "activeUsersInAutoPilot",
      key: "activeUsersInAutoPilot",
      align: "center",
      width: 120,
      sorter: (a: any, b: any) =>
        a?.activeUsersInAutoPilot - b?.activeUsersInAutoPilot,
      render: (count: number) => <b>{count ?? 0}</b>,
    },
    {
      title: "Active Users in Pre-Auto Pilot",
      dataIndex: "activeUsersInPreAutoPilot",
      key: "activeUsersInPreAutoPilot",
      align: "center",
      width: 120,
      sorter: (a: any, b: any) =>
        a?.activeUsersInPreAutoPilot - b?.activeUsersInPreAutoPilot,
      render: (count: number) => <b>{count ?? 0}</b>,
    },
    {
      title: "Suspend Data in Auto Pilot",
      dataIndex: "suspendDataInAutoPilot",
      key: "suspendDataInAutoPilot",
      align: "center",
      width: 120,
      sorter: (a: any, b: any) =>
        a?.suspendDataInAutoPilot - b?.suspendDataInAutoPilot,
      render: (count: number) => <b>{count ?? 0}</b>,
    },
    {
      title: "Transitioned Count",
      dataIndex: "transitionedCount",
      key: "transitionedCount",
      align: "center",
      width: 120,
      sorter: (a: any, b: any) => a?.transitionedCount - b?.transitionedCount,
      render: (count: number) => <b>{count ?? 0}</b>,
    },
    {
      title: "Not Transitioned Count",
      dataIndex: "notTransitionedCount",
      key: "notTransitionedCount",
      align: "center",
      width: 120,
      sorter: (a: any, b: any) =>
        a?.notTransitionedCount - b?.notTransitionedCount,
      render: (count: number) => <b>{count ?? 0}</b>,
    },
  ];

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40, marginTop: 40 }}>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={14}>
            <ReusableChart
              type="bar"
              series={chartSeries}
              categories={dateLabels.map((label) => label.label)}
              height={chartHeight}
            />
          </Col>
          <Col xs={24} xl={10} ref={tableRef}>
            <Table
              rowKey={(record) => record.label}
              loading={loading}
              dataSource={dataSource}
              columns={columns}
              scroll={{ x: "100%" }}
              pagination={false}
              tableLayout="fixed"
              summary={(pageData) => {
                let totalActiveUsersInAutoPilot = 0;
                let totalActiveUsersInPreAutoPilot = 0;
                let totalSuspendDataInAutoPilot = 0;
                let totalTransitionedCount = 0;
                let totalNotTransitionedCount = 0;

                pageData.forEach((record) => {
                  totalActiveUsersInAutoPilot +=
                    record.activeUsersInAutoPilot || 0;
                  totalActiveUsersInPreAutoPilot +=
                    record.activeUsersInPreAutoPilot || 0;
                  totalSuspendDataInAutoPilot +=
                    record.suspendDataInAutoPilot || 0;
                  totalTransitionedCount += record.transitionedCount || 0;
                  totalNotTransitionedCount += record.notTransitionedCount || 0;
                });

                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row className="summary-cell">
                      <Table.Summary.Cell
                        index={0}
                        align="center"
                        className="summary-cell"
                      >
                        <b>Total</b>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={1}
                        align="center"
                        className="summary-cell"
                      >
                        <b>{totalActiveUsersInAutoPilot}</b>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={2}
                        align="center"
                        className="summary-cell"
                      >
                        <b>{totalActiveUsersInPreAutoPilot}</b>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={3}
                        align="center"
                        className="summary-cell"
                      >
                        <b>{totalSuspendDataInAutoPilot}</b>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={4}
                        align="center"
                        className="summary-cell"
                      >
                        <b>{totalTransitionedCount}</b>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={5}
                        align="center"
                        className="summary-cell"
                      >
                        <b>{totalNotTransitionedCount}</b>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default MasTmAutoAndPreAutoPilotReport;
