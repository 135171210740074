import { Button, Drawer, Checkbox, Divider, Form, notification } from 'antd';
import React, { useState } from 'react'
// Types
import { IRoleResponseData } from "../../types/Role";
// import { IPermissionResponseData } from '../../types/Permission';
// @ts-ignore
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
//Services
import RoleService from '../../services/RoleService';
import PermissionService from '../../services/PermissonService';
import { NotificationPlacement } from 'antd/es/notification/interface';

interface RoleDetailProps {
    id: Number;
}

const RoleDetail: React.FC<RoleDetailProps> = ({ id }) => {

    const [api, contextHolder] = notification.useNotification();

    const openNotification = () => {

        let placement: NotificationPlacement = 'bottomRight';
        api.success({
            message: `İşlem Başarılı`,
            placement,
        });
    };

    const [open, setOpen] = useState(false);
    const [role, setRole] = useState<IRoleResponseData>();
    const [selectedPermissions, setSelectedPermissions] = useState<any[]>([]);
    const [permissionItems, setPermissionItems] = useState();

    const showDrawer = () => {
        setOpen(true);
        getPermissions();
    };

    const onClose = () => {
        setOpen(false);
    };

    const getRole = (id: Number) => {
        RoleService.get(id)
            .then((response: any) => {
                setRole(response.data);
                let ids: number[] = response.data.permissions.map((permission: any) => permission.id);
                console.log('ids', ids);
                setSelectedPermissions(ids);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    const getPermissions = () => {
        PermissionService.getAll()
            .then((response: any) => {
                // setPermissions(response.data);
                const sortedData = [...response.data].sort((a, b) => a.name.localeCompare(b.name));
                forRoles(sortedData)
                getRole(id);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    const forRoles = (permissionlist: any) => {
        const permissionCheckboxList: any = []

        for (let index = 0; index < permissionlist.length; index++) {
            const allPermissions = permissionlist[index];
            let obj = {
                label: allPermissions.name,
                value: allPermissions.id,
            }
            permissionCheckboxList.push(obj)
        }
        setPermissionItems(permissionCheckboxList)
    }

    const onChange = (checkedValues: CheckboxValueType[]) => {
        console.log(checkedValues);
        setSelectedPermissions(checkedValues);
    };

    const updateRole = () => {
        let data: any = {
            name: role?.name,
            description: role?.description,
            permissionIds: selectedPermissions
        }

        RoleService.update(id, data)
            .then((response: any) => {
                openNotification();
            })
            .catch((e: Error) => {
                console.log(e);
            });

        console.log(selectedPermissions)
    }

    return (
        <>
            {contextHolder}
            <Button type="primary" onClick={showDrawer}>
                Detail
            </Button>
            <Drawer title="Role Detail" placement="right" onClose={onClose} open={open}>
                <Form
                    layout="horizontal"
                    style={{ maxWidth: 600 }}
                    onFinish={updateRole}
                >
                    <h3>{role?.description}</h3>
                    <Divider />
                    <Checkbox.Group value={selectedPermissions} style={{ maxWidth: "150px" }} options={permissionItems} onChange={onChange} />
                    <Divider />
                    {/* <Button type="primary" htmlType="submit">
                        Submit
                    </Button> */}
                </Form>
            </Drawer>
        </>
    )

}

export default RoleDetail;
