import {
  Card,
  Col,
  Row,
  Typography,
  Input,
  Table,
  Button,
  Breadcrumb,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import ReportService from "../../../services/ReportService";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import DateRange from "../../Common/DateRange";
import { NavLink } from "react-router-dom";
import { ReportsRouter } from "../ReportsRouter";

const { Title } = Typography;

export const Hi5LessonsReport = () => {
  const [data, setData] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [searchText, setSearchText] = useState("");
  const [dateRange, setDateRange] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  function downloadExcel() {
    const columnNameMapping: any = {
      courseName: "Course Name",
      lessonName: "Lesson Name",
      startedDate: "Start Date",
      finishedDate: "Finish Date",
    };
  
    const dataWithCustomHeaders = filteredData.map((row: any) => {
      const newRow: any = {};
      Object.keys(row).forEach((key) => {
        const newKey = columnNameMapping[key] || key;
        newRow[newKey] = 
          key === "startedDate" || key === "finishedDate"
            ? dayjs(row[key]).format("MM/DD/YYYY HH:mm:ss")
            : row[key] !== null && row[key] !== undefined
            ? row[key]
            : "N/A";
      });
      return newRow;
    });
  
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(dataWithCustomHeaders);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Lessons");
  
    const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
  
    function s2ab(s: string) {
      const buffer = new ArrayBuffer(s.length);
      const view = new Uint8Array(buffer);
      for (let i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buffer;
    }
  
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
    saveAs(blob, "Hi5_ACM_Lessons_Report.xlsx");
  }  

  const handleSearch = (e: any) => {
    const value = e.target.value;
    if (value === "") {
      setSearchText(value);
      setFilteredData(data);
    } else {
      setSearchText(value);
      const filtered = data.filter(
        (item: any) =>
          (item.courseName &&
            item.courseName.toLowerCase().includes(value.toLowerCase())) ||
          (item.lessonName &&
            item.lessonName.toLowerCase().includes(value.toLowerCase()))
      );
      setFilteredData(filtered);
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: "Course Name",
      dataIndex: "courseName",
      key: "courseName",
      ellipsis: true,
      align: "center",
      width: 100,
      sorter: (a: any, b: any) => a.courseName.localeCompare(b.courseName),
      render: (_: any, row: any) => {
        return <b>{row?.courseName}</b>;
      },
    },
    {
      title: "Lesson Name",
      dataIndex: "lessonName",
      key: "lessonName",
      align: "center",
      ellipsis: true,
      width: 100,
      sorter: (a: any, b: any) => a.lessonName - b.lessonName,
    },
    {
      title: "Start Date",
      dataIndex: "startedDate",
      key: "startedDate",
      align: "center",
      width: 100,
      sorter: (a: any, b: any) => a.startedDate - b.startedDate,
      render: (text: any) =>
        text ? dayjs(text).format("MM/DD/YYYY HH:mm:ss") : "N/A",
    },
    {
      title: "Finish Date",
      dataIndex: "finishedDate",
      key: "finishedDate",
      align: "center",
      width: 100,
      sorter: (a: any, b: any) => a.finishedDate - b.finishedDate,
      render: (text: any) =>
        text ? dayjs(text).format("MM/DD/YYYY HH:mm:ss") : "N/A",
    },
  ];

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      getReport();
    }
    // eslint-disable-next-line
  }, [dateRange]);

  const getReport = () => {
    setLoading(true);
    ReportService.getLearnerReport(
      `?startDate=${dateRange[0]}T00:00:00&endDate=${dateRange[1]}T23:59:59`
    )
      .then((response: any) => {
        const updatedData =
          response?.data?.map((item: any) => ({
            ...item,
          })) || [];
        setData(updatedData);
        setFilteredData(updatedData);
        setLoading(false);
      })
      .catch((e: Error) => {
        console.log(e);
        setLoading(false);
      });
  };

  return (
    <>
      <Row>
        <Col>
          <Title className="onemaz-page-title" level={3}>
            Reports
          </Title>
          <Breadcrumb
            items={[
              {
                title: <NavLink to="/">Home</NavLink>,
              },
              {
                title: "Hi5 Account Manager Reports",
              },
              {
                title: "Lessons",
              },
            ]}
          />
        </Col>
      </Row>
      <ReportsRouter />

      <Card
        className="card-customers"
        title={
          <>
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <div
                className="customer-card-title"
                style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
              >
                Hi5 Account Manager
              </div>
              <div className="customer-card-info">Lessons</div>
            </div>
            <Input
              className="search-input"
              addonBefore={<SearchOutlined />}
              placeholder="Search"
              value={searchText}
              onChange={handleSearch}
              style={{ marginBottom: 16 }}
              size="large"
            />
          </>
        }
        bordered={true}
        extra={
          <>
            <Row justify={"end"}>
              {" "}
              <DateRange onChangeDateRange={setDateRange} />
              <Button
                icon={<DownloadOutlined />}
                style={{ marginLeft: 16 }}
                size="large"
                onClick={downloadExcel}
                type="primary"
              >
                Export
              </Button>
            </Row>
          </>
        }
      >
        <Table
          loading={loading}
          rowKey={(record: any) => record?.id}
          sticky
          scroll={{ x: "100%" }}
          columns={columns}
          dataSource={filteredData}
          pagination={false}
        />
      </Card>
    </>
  );
};
