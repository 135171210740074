import React, { useState } from "react";
import { Button } from "antd";
import "./info-card.css";
import { DownloadOutlined } from "@ant-design/icons";
import { convertDate, openNotification } from "../../utils";
import * as XLSX from "xlsx";
import moment from "moment";
import { saveAs } from "file-saver";
import ProspectCustomerService from "../../services/ProspectCustomerService";
import CustomerService from "../../services/CustomerService";
import AmazonSuspendedApplicantService from "../../services/AmazonSuspendedApplicantService";

const DownloadExcel: React.FC<any> = ({ filterText }) => {
  const roleName = localStorage.getItem("roleName");
  const [loading, setLoading] = useState(false);
  const authUserId = localStorage.getItem("id");

  const copyEmailList = (data: any) => {
    const emailList = data.map((item: any) => {
      return item?.email;
    });
    navigator.clipboard.writeText(emailList.join("; "));
    openNotification("success", "Success", "Email addresses copied");
  };

  function downloadExcel(jsonData: any[]) {
    let data: any;
    if (roleName === "CALL_TEAM_MANAGER" || roleName === "CALL_TEAM") {
      data = jsonData.map((item: any) => ({
        Source: item?.source?.name ?? "-",
        Fullname: item?.fullName ?? "-",
        "Created Date": convertDate(item?.createdAt ?? "-"),
        "Call Center Member": `${item?.callCenterMember?.firstName ?? "-"} ${
          item?.callCenterMember?.lastName ?? "-"
        }`,
        Status: `${item?.callCenterStatus1?.name ?? "-"}`,
        "Call Center Detail Main Statu": `${
          item?.callCenterStatusDetail?.parent?.name ?? "-"
        }`,
        "Call Center Detail Sub Statu": `${item?.callCenterStatusDetail?.name ?? "-"}`,
        Phone: item?.phone ?? "-",
        Email: item?.email ?? "-",
        City: item?.city ?? "-",
        Message: item?.message ?? "-",
        "Source of Referral": item?.sourceOfReferral ?? "-",
        // Diğer özel alanlar
      }));
    } else if (
      roleName === "SUSPEND_TEAM_MANAGER" ||
      roleName === "SUSPEND_TEAM"
    ) {
      data = jsonData.map((item: any) => ({
        Source: item?.source?.name ?? "-",
        Fullname: item?.fullName ?? "-",
        "Created Date": convertDate(item?.createdAt ?? "-"),
        "Suspend Team Member": `${item?.suspendTeamMember?.firstName ?? "-"} ${
          item?.suspendTeamMember?.lastName ?? "-"
        }`,
        Phone: item?.phone ?? "-",
        Email: item?.email ?? "-",
        Status: `${item?.amazonSuspendedApplicantStatus?.name ?? "-"}`,
        Type: `${item?.amazonSuspendedApplicantSuspendType?.name ?? "-"}`,
        Package: `${item?.callCenterSalesPackage?.name ?? "-"}`,
        Seller_Shield: `${item?.additionalPackageHistory ? "Yes" : "No"}`,
        OneAmz_User: `${item?.oneamzEmail ? "Yes" : "No"}`,
        Seller_Email: `${item?.sellerEmail ?? "-"}`,
        Buyer_Email: `${item?.buyerEmail ?? "-"}`,
        OneAmz_Email: `${item?.oneamzEmail ?? "-"}`,
        Notes: `${
          item?.noteList?.length
            ? item?.noteList.map((note: any) => note.description).join(", \n")
            : "-"
        }`,
        // Diğer özel alanlar
      }));
    } else if (
      roleName === "SUCCESS_TEAM_MANAGER" ||
      roleName === "SUCCESS_TEAM"
    ) {
      data = jsonData.map((customer) => {
        let notes = customer?.notes?.map((item: any) => item?.description);

        const status = customer?.status?.name;

        return {
          SuccessTeamMember: `${
            customer?.successTeamMember?.firstName ?? "-"
          } ${customer?.successTeamMember?.lastName ?? "-"}`,
          ...(() => {
            const {
              createdAt,
              user,
              hi5AcmMember,
              callCenterMember,
              callCenterStatus,
              successTeamMember,
              registeredAt,
              lastLoginDate,
              integratedAt,
              ...rest
            } = customer;
            return rest;
          })(),
          notes: notes ? notes.join(", \n") : "",
          status: status || "",
          lastLoginAt: customer?.lastLoginDate
            ? moment(customer?.lastLoginDate).format("DD/MM/YYYY")
            : "",
          IntegratedAt: customer?.integratedAt
            ? moment(customer?.integratedAt).format("DD/MM/YYYY")
            : "",
          createdAt: customer?.createdAt
            ? moment(customer?.createdAt).format("DD/MM/YYYY")
            : "",
          registeredAt: customer?.registeredAt
            ? moment(customer?.registeredAt).format("DD/MM/YYYY")
            : "",
        };
      });
    } else if (
      roleName === "HI5_ACM_MANAGER" ||
      roleName === "HI5_ACM_MEMBER"
    ) {
      data = jsonData.map((customer: any) => {
        let notes = customer?.notes?.map((item: any) => item?.description);
        let tasks = customer?.tasks?.map((item: any) => item?.description);
        const status = customer?.status?.name;
        const additionalPackageHistory =
          customer?.additionalPackageHistoryList?.map((item: any) => {
            const startDate = item?.startDate
              ? moment(item?.startDate).format("DD/MM/YYYY HH:mm")
              : "";
            const endDate = item?.endDate
              ? moment(item?.endDate).format("DD/MM/YYYY HH:mm")
              : "";
            return `${item?.packageName} (${startDate} - ${endDate})`;
          });

        return {
          Hi5_ACM_Member: `${customer?.hi5AcmMember?.firstName ?? ""} ${
            customer?.hi5AcmMember?.lastName ?? ""
          }`,
          ...(() => {
            const {
              becomeCustomerAt,
              user,
              callCenterStatus,
              callCenterMember,
              successTeamMember,
              hi5AcmMember,
              learner,
              freeDays,
              createdAt,
              updatedAt,
              registeredAt,
              oneamzAppId,
              status,
              notes,
              tasks,
              lastLoginDate,
              integratedAt,
              lastContactedDateAcm,
              lastContactedAcm,
              retentionAssignmentDate,
              lastContactedDateStm,
              lastContactedStm,
              additionalPackageHistoryList,
              packageOfInterestSTM,
              oldPackageOfInterestSTM,
              packageOfInterestACM,
              currentPackageOfInterestACM,
              ...rest
            } = customer;
            return rest;
          })(),
          Additional_Package_History_List: additionalPackageHistory
            ? additionalPackageHistory.join(", \n")
            : "",
          Notes: notes ? notes.join(", \n") : "",
          Tasks: tasks ? tasks.join(", \n") : "",
          Status: status || "",
          Last_Login_Date: customer?.lastLoginDate
            ? moment(customer?.lastLoginDate).format("DD/MM/YYYY")
            : "",
          Integrated_At: customer?.becomeCustomerAt
            ? moment(customer?.becomeCustomerAt).format("DD/MM/YYYY")
            : "",
          Created_At: customer?.createdAt
            ? moment(customer?.createdAt).format("DD/MM/YYYY")
            : "",
        };
      });
    } else if (
      roleName === "MAS_TEAM_MANAGER" ||
      roleName === "MAS_TEAM_MEMBER"
    ) {
      data = jsonData.map((item: any) => {
        const relevantPackages =
          item?.additionalPackageHistoryList?.filter(
            (pkg: any) => pkg.packageId === 3 || pkg.packageId === 6
          ) ?? [];
        const latestPackage = relevantPackages.reduce(
          (latest: any, pkg: any) => {
            return new Date(pkg.endDate) > new Date(latest.endDate)
              ? pkg
              : latest;
          },
          relevantPackages[0] || {}
        );
        const additionalPackageHistory =
          item?.additionalPackageHistoryList
            ?.map((pkg: any) => {
              const startDate = pkg?.startDate
                ? moment(pkg?.startDate).format("DD/MM/YYYY HH:mm")
                : "";
              const endDate = pkg?.endDate
                ? moment(pkg?.endDate).format("DD/MM/YYYY HH:mm")
                : "";
              return `${pkg?.packageName} (${startDate} - ${endDate})`;
            })
            .join(", \n") || "-";
        console.log("additionalPackageHistory", additionalPackageHistory);
        return {
          "Created Date": convertDate(item?.createdAt ?? "-"),
          "Register Date": convertDate(item?.registeredAt ?? "-"),
          Fullname: item?.fullName ?? "-",
          Phone: item?.phone ?? "-",
          Email: item?.email ?? "-",
          "Assignment Date": convertDate(item?.masAssignmentDate ?? "-"),
          "MAS Team Member": `${item?.masTeamMember?.firstName ?? "-"} ${
            item?.masTeamMember?.lastName ?? "-"
          }`,
          "Feedback Status": `${item?.feedbackStatus?.name ?? "-"}`,
          "Feedback Channel": `${item?.feedbackChannel?.name ?? "-"}`,
          "Additional Package History":
            item?.additionalPackageHistoryList
              ?.map((pkg: any) => {
                const startDate = pkg?.startDate
                  ? moment(pkg?.startDate).format("DD/MM/YYYY HH:mm")
                  : "";
                const endDate = pkg?.endDate
                  ? moment(pkg?.endDate).format("DD/MM/YYYY HH:mm")
                  : "";
                return `${pkg?.packageName} (${startDate} - ${endDate})`;
              })
              .join(", \n") || "-",
          "Package Info": latestPackage.packageName
            ? `${latestPackage.packageName} (${moment(
                latestPackage.startDate
              ).format("DD/MM/YYYY HH:mm")} - ${moment(latestPackage.endDate).format(
                "DD/MM/YYYY HH:mm"
              )})`
            : "-",
          "Suspend Team Department": item?.suspendTeam ? "Suspended" : "Active",
          "Subscription End Date": convertDate(
            item?.subscriptionEndDate ?? "-"
          ),
          Notes: item?.notes?.map((item: any) => item?.description) ?? "-",
          Tasks: item?.tasks?.map((item: any) => item?.description) ?? "-",
        };
      });
    } else {
      data = jsonData.map((customer: any) => {
        let notes = customer?.notes?.map((item: any) => item?.description);
        let tasks = customer?.tasks?.map((item: any) => item?.description);
        const status = customer?.status?.name;
        const additionalPackageHistory =
          customer?.additionalPackageHistoryList?.map((item: any) => {
            const startDate = item?.startDate
              ? moment(item?.startDate).format("DD/MM/YYYY HH:mm")
              : "";
            const endDate = item?.endDate
              ? moment(item?.endDate).format("DD/MM/YYYY HH:mm")
              : "";
            return `${item?.packageName} (${startDate} - ${endDate})`;
          });

        return {
          Account_Manager: `${customer?.user?.firstName ?? ""} ${
            customer?.user?.lastName ?? ""
          }`,
          ...(() => {
            const {
              becomeCustomerAt,
              user,
              notes,
              tasks,
              createdAt,
              updatedAt,
              registeredAt,
              oneamzAppId,
              lastLoginDate,
              integratedAt,
              oldPackageOfInterestSTM,
              packageOfInterestHi5Acm,
              packageOfInterestSTM,
              currentPackageOfInterestHi5Acm,
              lastContactedDateHi5Acm,
              lastContactedDateStm,
              lastContactedHi5Acm,
              lastContactedStm,
              freeDays,
              status,
              callCenterMember,
              additionalPackageHistoryList,
              hi5AcmAssignmentDate,
              hi5AcmMember,
              learner,
              successTeamMember,
              callCenterStatus,
              ...rest
            } = customer;
            return rest;
          })(),
          Additional_Package_History_List: additionalPackageHistory
            ? additionalPackageHistory.join(", \n")
            : "",
          Notes: notes ? notes.join(", \n") : "",
          Tasks: tasks ? tasks.join(", \n") : "",
          Status: status || "",
          LastLogin_Date: customer?.lastLoginDate
            ? moment(customer?.lastLoginDate).format("DD/MM/YYYY")
            : "",
          Integrated_At: customer?.becomeCustomerAt
            ? moment(customer?.becomeCustomerAt).format("DD/MM/YYYY")
            : "",
          Created_At: customer?.createdAt
            ? moment(customer?.createdAt).format("DD/MM/YYYY")
            : "",
        };
      });
    }

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert JSON to worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Write the workbook to a binary string
    const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });

    function s2ab(s: string) {
      const buffer = new ArrayBuffer(s.length);
      const view = new Uint8Array(buffer);
      for (let i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buffer;
    }

    // Save the workbook to a file
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });

    // Use FileSaver to save the file
    saveAs(blob, "customer-list.xlsx");
  }

  const getAllWithoutPagination = (download: any = true) => {
    setLoading(true);
    let params = new URLSearchParams(filterText);
    params.delete("page");
    params.delete("size");
    let filterCriteria = `?${params}`;
    if (roleName === "CALL_TEAM_MANAGER" || roleName === "CALL_TEAM") {
      ProspectCustomerService.getAllWithoutPagination(filterCriteria)
        .then((response: any) => {
          if (download) {
            downloadExcel(response.data);
          } else {
            copyEmailList(response.data);
          }
          setLoading(false);
        })
        .catch((e: Error) => {
          setLoading(false);
        });
    } else if (roleName === "ACCOUNT_MANAGER_MANAGER") {
      CustomerService.getAllWithoutPagination(
        `${filterCriteria}&memberFlag=ACCOUNT_MANAGER`
      )
        .then((response: any) => {
          if (download) {
            downloadExcel(response.data);
          } else {
            copyEmailList(response.data);
          }
          setLoading(false);
        })
        .catch((e: Error) => {
          setLoading(false);
        });
    } else if (roleName === "ACCOUNT_MANAGER") {
      CustomerService.getAllWithoutPaginationMe(
        `${filterCriteria}&memberFlag=ACCOUNT_MANAGER`
      )
        .then((response: any) => {
          if (download) {
            downloadExcel(response.data);
          } else {
            copyEmailList(response.data);
          }
          setLoading(false);
        })
        .catch((e: Error) => {
          setLoading(false);
        });
    } else if (roleName === "HI5_ACM_MANAGER") {
      CustomerService.getAllWithoutPagination(
        `${filterCriteria}&memberFlag=HI5_ACM_MEMBER`
      )
        .then((response: any) => {
          if (download) {
            downloadExcel(response.data);
          } else {
            copyEmailList(response.data);
          }
          setLoading(false);
        })
        .catch((e: Error) => {
          setLoading(false);
        });
    } else if (roleName === "HI5_ACM_MEMBER") {
      CustomerService.getAllWithoutPagination(
        `${filterCriteria}&memberFlag=HI5_ACM_MEMBER&hi5AcmMember=${authUserId}`
      )
        .then((response: any) => {
          if (download) {
            downloadExcel(response.data);
          } else {
            copyEmailList(response.data);
          }
          setLoading(false);
        })
        .catch((e: Error) => {
          setLoading(false);
        });
    } else if (roleName === "MAS_TEAM_MANAGER") {
      CustomerService.getAllWithoutPagination(
        `${filterCriteria}&memberFlag=MAS_TEAM_MEMBER`
      )
        .then((response: any) => {
          if (download) {
            downloadExcel(response.data);
          } else {
            copyEmailList(response.data);
          }
          setLoading(false);
        })
        .catch((e: Error) => {
          setLoading(false);
        });
    } else if (roleName === "MAS_TEAM_MEMBER") {
      CustomerService.getAllWithoutPagination(
        `${filterCriteria}&memberFlag=MAS_TEAM_MEMBER&masTeamMember=${authUserId}`
      )
        .then((response: any) => {
          if (download) {
            downloadExcel(response.data);
          } else {
            copyEmailList(response.data);
          }
          setLoading(false);
        })
        .catch((e: Error) => {
          setLoading(false);
        });
    } else if (roleName === "SUSPEND_TEAM_MANAGER") {
      AmazonSuspendedApplicantService.getAllWithoutPagination(
        filterCriteria
      ).then((response: any) => {
        if (download) {
          downloadExcel(response.data);
        } else {
          copyEmailList(response.data);
        }
        setLoading(false);
      });
    } else if (roleName === "SUSPEND_TEAM") {
      AmazonSuspendedApplicantService.getAllWithoutPagination(
        `${filterCriteria}&suspendTeamMemberId=${authUserId}`
      ).then((response: any) => {
        if (download) {
          downloadExcel(response.data);
        } else {
          copyEmailList(response.data);
        }
        setLoading(false);
      });
    } else if (roleName === "SUCCESS_TEAM") {
      CustomerService.getAllWithoutPagination(
        `${filterCriteria}&successTeamMember=${authUserId}`
      ).then((response: any) => {
        if (download) {
          downloadExcel(response.data);
        } else {
          copyEmailList(response.data);
        }
        setLoading(false);
      });
    } else {
      CustomerService.getAllWithoutPagination(filterCriteria)
        .then((response: any) => {
          if (download) {
            downloadExcel(response.data);
          } else {
            copyEmailList(response.data);
          }
          setLoading(false);
        })
        .catch((e: Error) => {
          setLoading(false);
        });
    }
  };

  return (
    <Button
      loading={loading}
      icon={<DownloadOutlined />}
      onClick={getAllWithoutPagination}
      type="primary"
      style={{ marginRight: 10 }}
    >
      Export
    </Button>
  );
};

export default DownloadExcel;
