import {
    Breadcrumb,
    Col,
    Row,
    Typography,
    Table,
    Button,
    Form,
    Modal,
    Input,
    Switch,
  } from "antd";
  import type { ColumnsType } from "antd/es/table";
  import { motion } from "framer-motion";
  import { useEffect, useState } from "react";
  import { openNotification } from "../../utils";
  import SettingsRouter from "./SettingsRouter";
  import { NavLink } from "react-router-dom";
  import RetentionStatusService from "../../services/RetentionStatusService";
  
  const { Item } = Form;
  const { Title } = Typography;
  
  export const RetentionStatus = () => {
    const [form] = Form.useForm();
    const [formEditRetentionStatus] = Form.useForm();
    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [newRetentionStatus, setNewRetentionStatus] = useState<string>("");
    const [selectedRetentionStatus, setSelectedRetentionStatus] = useState<any>(null);
    const [processedData, setProcessedData] = useState<any[]>([]);
  
    const showCreateModal = () => {
      setIsCreateModalVisible(true);
    };
  
    const handleCreateModalCancel = () => {
      setIsCreateModalVisible(false);
    };
  
    const showEditModal = (retentionStatus: any) => {
      setSelectedRetentionStatus(retentionStatus);
      setNewRetentionStatus(retentionStatus.name);
      setIsEditModalVisible(true);
    };
  
    const handleEditModalCancel = () => {
      setIsEditModalVisible(false);
      setSelectedRetentionStatus(null);
    };
  
    // const showDeleteModal = (retentionStatus: any) => {
    //   setSelectedRetentionStatus(retentionStatus);
    //   setIsDeleteModalVisible(true);
    // };
  
    const handleDeleteModalCancel = () => {
      setIsDeleteModalVisible(false);
      setSelectedRetentionStatus(null);
    };
  
    // POST
    const postRetentionStatus = (data: any) => {
      setLoading(true);
      RetentionStatusService.create(data)
        .then((response: any) => {
          getRetentionStatus();
          form.resetFields();
        })
        .catch((e: Error) => {
          console.log(e);
        })
        .finally(() => setLoading(false));
    };
  
    const handleCreate = async () => {
      try {
        const values = await form.validateFields();
        postRetentionStatus(values);
        setIsCreateModalVisible(false);
      } catch (error) {
        console.error("Validation failed:", error);
      }
    };
  
    // GETALL
    const getRetentionStatus = () => {
      setLoading(true);
      RetentionStatusService.getAll()
        .then((response: any) => {
          let data = response.data;
          setProcessedData(data);
          setLoading(false);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    };
  
    // PUT UPDATE
    const handleChangeRetentionStatus = (
      id: number,
      name: string,
      active: boolean,
    ) => {
      setLoading(true);
      let data = {
        active: active,
        name: name
      };
      RetentionStatusService.update(id, data)
        .then((response: any) => {
          openNotification(
            "success",
            "Success",
            `Retention status updated`
          );
          handleEditModalCancel();
          getRetentionStatus();
        })
        .catch((e: Error) => {
          console.log(e);
        })
        .finally(() => setLoading(false));
    };
  
    const handleActiveChange = (record: any, checked: boolean) => {
      handleChangeRetentionStatus(record.id, record.name, checked);
    };
  
    // DELETE
    const handleDeleteRetentionStatus = (id: number) => {
      setLoading(true);
      RetentionStatusService.remove(id)
        .then((response: any) => {
          openNotification(
            "success",
            "Success",
            `Retention Status deleted`
          );
          handleDeleteModalCancel();
          getRetentionStatus();
        })
        .catch((e: Error) => {
          console.log(e);
        })
        .finally(() => setLoading(false));
    };
  
    const columns: ColumnsType<any> = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        defaultSortOrder: "ascend",
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (_, row) => <span>{row.name}</span>,
      },
      {
        title: "Active/Passive",
        dataIndex: "active",
        key: "active",
        width: 200,
        align: "center",
        sorter: (a, b) => a.active - b.active,
        render: (_, record) => (
          <Switch
            checked={record.active}
            onChange={(checked) => handleActiveChange(record, checked)}
          />
        ),
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        align: "center",
        width: 200,
        render: (_, record) => (
          // <ButtonGroup style={{ marginLeft: "10px", justifyContent: "flex-end" }}>
            <Button type="primary" onClick={() => showEditModal(record)}>
              Edit
            </Button>
            /* <Button onClick={() => showDeleteModal(record)}>Delete</Button> */
          // </ButtonGroup>
        ),
      },
    ];
  
    useEffect(() => {
      getRetentionStatus();
    }, []);
  
    useEffect(() => {
      formEditRetentionStatus.setFieldsValue({ selectedSource: newRetentionStatus });
      // eslint-disable-next-line
    }, [newRetentionStatus]);
  
    return (
      <motion.div
        initial={{ y: 200, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 300, opacity: 0 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 40,
        }}
      >
        <div>
          <Row>
            <Col>
              <Title className="onemaz-page-title" level={3}>
                {}
              </Title>
              <Breadcrumb
                items={[
                  {
                    title: <NavLink to="/">Home</NavLink>,
                  },
                  {
                    title: "Settings",
                  },
                  {
                    title: "Retention Status",
                  },
                ]}
              />
            </Col>
          </Row>
          <SettingsRouter />
          <Button type="primary" onClick={showCreateModal}>
            Create Retention Status
          </Button>
          <Row style={{ marginTop: "30px" }}>
            <Col span={24}>
              <Table
                loading={loading}
                columns={columns}
                dataSource={processedData}
                scroll={{ x: "max-content" }}
                pagination={false}
                rowKey={"id"}
                sticky
              />
            </Col>
          </Row>
          {/* Create Modal */}
          <Modal
            title="Create New Retention Status"
            open={isCreateModalVisible}
            onOk={handleCreate}
            onCancel={handleCreateModalCancel}
          >
            <Form form={form} layout="vertical">
              <Item
                name="name"
                label="Retention Status Name"
                rules={[
                  {
                    required: true,
                    message: "Retention status name required",
                  },
                ]}
              >
                <Input style={{ width: "100%" }} />
              </Item>
            </Form>
          </Modal>
          {/* Edit Modal */}
          <Modal
            title="Edit Retention Status"
            open={isEditModalVisible}
            onCancel={handleEditModalCancel}
            footer={[
              <Button key="back" onClick={handleEditModalCancel}>
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={() =>
                  handleChangeRetentionStatus(
                    selectedRetentionStatus?.id,
                    newRetentionStatus,
                    selectedRetentionStatus?.active
                  )
                }
              >
                Save
              </Button>,
            ]}
          >
            <Form form={formEditRetentionStatus} layout="vertical">
              <Item
                name="selectedSource"
                label="Retention Status"
                rules={[
                  {
                    required: true,
                    message: "Retention status name required",
                  },
                ]}
              >
                <Input
                  value={newRetentionStatus}
                  onChange={(e) => setNewRetentionStatus(e.target.value)}
                />
              </Item>
            </Form>
          </Modal>
          {/* Delete Modal */}
          <Modal
            title="Delete Retention Status"
            open={isDeleteModalVisible}
            onCancel={handleDeleteModalCancel}
            footer={[
              <Button key="back" onClick={handleDeleteModalCancel}>
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={() => handleDeleteRetentionStatus(selectedRetentionStatus?.id)}
              >
                Delete
              </Button>,
            ]}
          >
            <p>Are you sure you want to delete this retention status?</p>
          </Modal>
        </div>
      </motion.div>
    );
  };
  