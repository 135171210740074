import http from "../http-common";
import { IAutoAndPreAutoPilotResponse } from "../types/MasTeamReport";

const getAutoAndPreAutoPilotReport = (startDate: string, endDate: string) => {
  return http.get<Array<IAutoAndPreAutoPilotResponse>>(
    `/report/mas-team/summary-report?startDate=${startDate}&endDate=${endDate}`
  );
};

const MasTeamReportService = {
  getAutoAndPreAutoPilotReport,
};

export default MasTeamReportService;
