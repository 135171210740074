import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import { Dashboard } from "./pages/Dashboard";
import { LearnerList } from "./pages/LearnerList";
import { Profile } from "./pages/Profile";
import CustomerDetail from "./pages/CustomerDetail";
import { Task } from "./pages/Task";
import Login from "./pages/Login";
import MainLayout from "./pages/Layout";
import { Settings } from "./pages/Settings";
import { LearnerStatus } from "./components/settings/LearnerStatus";
import { UserList } from "./components/settings/UserList";
import { RoleList } from "./components/settings/RoleList";
import { BonusPolicy } from "./components/settings/BonusPolicy";
import NotFound from "./pages/NotFound";
import UserDetail from "./pages/UserDetail";
import { CustomerList } from "./components/Dashboard/AcmDashboard/CustomerList";
import { CallStatus } from "./components/settings/CallStatus";
import { CtmCustomerList } from "./components/Dashboard/CtmDashboard/CustomerList";
import LearnerDetail from "./components/LearnerDetail/LearnerDetail";
import { ACMStatus } from "./components/settings/ACMStatus";
import { ProspectCustomers } from "./components/Dashboard/ProspectCustomer/ProspectCustomers";
import ProspectCustomerDetail from "./pages/ProspectCustomerDetail";
import { CallSalesPackage } from "./components/settings/CallSalesPackage";
import { CallSalesPackageServey } from "./components/settings/CallSalesPackageServey";
import { Affiliate } from "./components/settings/Affiliate";
import { SoftwareSuspendStatus } from "./components/settings/SoftwareSuspendStatus";
import SalesApproval from "./components/CustomerDetail/SalesApproval";
import { ProspectCustomerSources } from "./components/settings/ProspectCustomerSources";
import { AmazonSuspendedApplicantList } from "./components/Dashboard/SuspendTeamDashboard/AmazonSuspendedApplicantList";
import { AmazonSuspendedApplicantStatus } from "./components/settings/AmazonSuspendedApplicantStatus";
import { AmazonSuspendedApplicantSuspendTypes } from "./components/settings/AmazonSuspendedApplicantSuspendTypes";
import { AmazonSuspendedApplicantInputs } from "./components/settings/AmazonSuspendedApplicantInputs";
import AmazonSuspendedApplicantDetail from "./pages/AmazonSuspendedApplicantDetail";
import { StmSuspendedCustomerList } from "./components/Dashboard/StmDashboard/SuspendCustomerList";
import { RetentionCustomerList } from "./components/Dashboard/StmDashboard/RetentionCustomerList";
import { DepartmentList } from "./components/settings/DepartmentList";
import { EmployeeList } from "./components/settings/EmployeeList";
import { EmployeesList } from "./pages/EmployeesList";
import Feedback from "./pages/Feedback";
import { RetentionStatus } from "./components/settings/RetentionStatus";
import { Hi5CustomerList } from "./components/Dashboard/Hi5AcmDashboard/Hi5CustomerList";
import { Hi5Call } from "./components/Report/Hi5AcmReport/Hi5Call";
import { Hi5Status } from "./components/Report/Hi5AcmReport/Hi5Status";
import { Hi5CurrentPackage } from "./components/Report/Hi5AcmReport/Hi5CurrentPackage";
import { TimeOffSchedule } from "./pages/TimeOffSchedule";
import { StmEducationReport } from "./components/Report/StmReport/StmEducationReport";
import { StmSuccessTeamReport } from "./components/Report/StmReport/StmSuccessTeamReport";
import { StmCallHistoryReport } from "./components/Report/StmReport/StmCallHistoryReport";
import { StmAffiliateReport } from "./components/Report/StmReport/StmAffiliateReport";
import { Reports } from "./pages/Reports";
import { FeedbackReport } from "./components/Report/CommonReport/FeedbackReport/FeedbackReport";
import { Call } from "./components/Report/AcmReport/Call";
import { Status } from "./components/Report/AcmReport/Status";
import { CurrentPackage } from "./components/Report/AcmReport/CurrentPackage";
import { PackageOfInterestStatusForStm } from "./components/settings/PackageOfInterest/PackageOfInterestStatusForStm";
import { OldPackageOfInterestStatusForStm } from "./components/settings/OldPackageOfInterest/OldPackageOfInterestStatusForStm";
import { PackageOfInterestStatusForAcm } from "./components/settings/PackageOfInterest/PackageOfInterestStatusForAcm";
import { CurrentPackageOfInterestStatusForAcm } from "./components/settings/CurrentPackageOfInterest/CurrentPackageOfInterestStatusForAcm";
import { PackageOfInterestStatusForHi5Acm } from "./components/settings/PackageOfInterest/PackageOfInterestStatusForHi5Acm";
import { CurrentPackageOfInterestStatusForHi5Acm } from "./components/settings/CurrentPackageOfInterest/CurrentPackageOfInterestStatusForHi5Acm";
import { SalesLogReport } from "./components/Report/CommonReport/SalesLogReport";
import { InterestReport } from "./components/Report/CommonReport/InterestReport";
import { StmSoftwareSuspendedStatusReport } from "./components/Report/StmReport/StmSoftwareSuspendedStatusReport";
import { StmStatusReport } from "./components/Report/StmReport/StmStatusReport";
import { EarningsReport } from "./components/Report/CommonReport/EarningsReport/EarningsReport";
import { SuspendTmAmazonSuspendedApplicantStatusReport } from "./components/Report/SuspendTmReport/SuspendTmAmazonSuspendedApplicantStatusReport";
import { Hi5ACMStatus } from "./components/settings/Hi5ACMStatus";
import { MasCustomerList } from "./components/Dashboard/MasDashboard/MasCustomerList";
import { MasStatus } from "./components/settings/MasStatus";
import CustomerAccountHealth from "./components/MasDetail/CustomerAccountHealth/CustomerAccountHealth";
import CustomerOrders from "./components/MasDetail/CustomerOrders";
import CustomerShipments from "./components/MasDetail/CustomerShipments";
import CtmSummaryReport from "./components/Report/CtmReport/SummaryReport/CtmSummaryReport";
import { CtmCallHistoryReport } from "./components/Report/CtmReport/CtmCallHistoryReport";
import CustomerProfitAndLoss from "./components/MasDetail/CustomerProfitAndLoss";
import DropoutFeedbackList from "./pages/DropoutFeedbackList";
import { ReasonForDropoutStatus } from "./components/settings/ReasonForDropoutStatus";
import SummaryReport from "./components/Report/CommonReport/SummaryReport/SummaryReport";
import { AmazonSuspendedApplicantServiceTypes } from "./components/settings/AmazonSuspendedApplicantServiceTypes";
import { AmazonSuspendedApplicantActionTypes } from "./components/settings/AmazonSuspendedApplicantActionTypes";
import AmazonSuspendedApplicantReports from "./components/Report/SuspendTmReport/SuspendTmAmazonSuspendedApplicantReports/AmazonSuspendedApplicantReports";
import VerifyExternalToken from "./pages/VerifyExternalToken";
import { CallCenterDetailStatus } from "./components/settings/CallCenterDetailStatus";
import MarketingTmSummaryReport from "./components/Report/MarketingTmReport/SummaryReport/MarketingTmSummaryReport";
import MasTmSummaryReport from "./components/Report/MasTmReport/Summary Report/MasTmSummaryReport";
import { Hi5LessonsReport } from "./components/Report/Hi5AcmReport/Hi5Lessons";
import { AccountManagementCustomerList } from "./components/Dashboard/AccountManagementDashboard/AccountManagementCustomerList";

const isTokenExpired = (token: any) => {
  if (!token) {
    return true;
  }
  const payloadBase64 = token.split(".")[1];
  const decodedPayload = JSON.parse(window.atob(payloadBase64));
  const currentTime = Date.now() / 1000;
  return decodedPayload.exp < currentTime;
};

const App: React.FC = () => {
  const [roleName, setRoleName] = useState<any>(
    localStorage.getItem("roleName")
  );
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const logout = () => {
    localStorage.removeItem("accessToken");
    setIsLoggedIn(false);
  };

  useEffect(() => {
    const storedRoleName = localStorage.getItem("roleName");
    setRoleName(storedRoleName);
  }, [isLoggedIn]);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken && !isTokenExpired(accessToken)) {
      setIsLoggedIn(true);
    } else {
      logout();
    }

    const root = document.querySelector("#root");
    if (root) {
      const dm = localStorage.getItem("darkMode");
      if (dm) {
        // @ts-ignore
        root.classList.add("dark");
      }
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route
          key="Main"
          element={
            <MainLayout>
              <Outlet />
            </MainLayout>
          }
        >
          {/* Success Team Reporter Rolü için Rotalar */}
          {roleName === "SUCCESS_TEAM_REPORTER" && (
            <>
              <Route path="/" element={<Navigate replace to="/learners" />} />
              <Route path="/learners" element={<LearnerList />} />
              <Route path="/learners/detail/:id" element={<LearnerDetail />} />
              <Route
                path="/retention-customers"
                element={<RetentionCustomerList />}
              />
              <Route
                path="/customers/detail/:id"
                element={<CustomerDetail />}
              />
              <Route
                path="/stm-suspended-customers"
                element={<StmSuspendedCustomerList />}
              />
              <Route path="/users/detail/:id" element={<UserDetail />} />
              <Route path="*" element={<NotFound />} />
            </>
          )}

          {/* Call Team Manager Rolü için Rotalar */}
          {(roleName === "CALL_TEAM_MANAGER" ||
            roleName === "CALL_TEAM" ||
            roleName === "ADMIN") && (
            <>
              {/* {(roleName === "CALL_TEAM_MANAGER" ||
                roleName === "CALL_TEAM") && (
                <>
                  <Route path="/learners" element={<LearnerList />} />
                </>
              )} */}
              <Route
                path="/prospect-customers"
                element={<ProspectCustomers />}
              />
              {roleName === "CALL_TEAM" ? (
                <Route path="/customers" element={<CtmCustomerList />} />
              ) : (
                <>
                  <Route path="/customers" element={<CustomerList />} />
                  <Route
                    path="/settings/call-status"
                    element={<CallStatus />}
                  />
                  <Route
                    path="/settings/call-center-detail-status"
                    element={<CallCenterDetailStatus />}
                  />
                  <Route
                    path="/settings/call-sales-package-servey"
                    element={<CallSalesPackageServey />}
                  />
                  <Route
                    path="/settings/prospect-customer-sources"
                    element={<ProspectCustomerSources />}
                  />
                  {/* <Route
                    path="/settings/suspend-status"
                    element={<SuspendStatus />}
                  /> */}
                  <Route path="/settings" element={<Settings />} />
                  <Route path="/settings/users" element={<UserList />} />
                </>
              )}
              {/* <Route
                path="/suspended-customers"
                element={<CtmSuspendedCustomerList />}
              /> */}
              <Route path="/users/detail/:id" element={<UserDetail />} />
              <Route
                path="/prospect-customers/detail/:id"
                element={<ProspectCustomerDetail />}
              />
              <Route path="*" element={<NotFound />} />
            </>
          )}

          {/* Success Team Manager Rolü için Rotalar */}
          {(roleName === "SUCCESS_TEAM_MANAGER" || roleName === "ADMIN") && (
            <>
              <Route path="/learners" element={<LearnerList />} />
              <Route path="/learners/detail/:id" element={<LearnerDetail />} />
              <Route
                path="/retention-customers"
                element={<RetentionCustomerList />}
              />
              <Route
                path="/customers/detail/:id"
                element={<CustomerDetail />}
              />
              <Route
                path="/stm-suspended-customers"
                element={<StmSuspendedCustomerList />}
              />
              <Route path="/users/detail/:id" element={<UserDetail />} />
              <Route
                path="/dropout-feedback"
                element={<DropoutFeedbackList />}
              />
              <Route path="/settings" element={<Settings />} />
              <Route
                path="/settings/learner-status"
                element={<LearnerStatus />}
              />
              <Route
                path="/settings/retention-status"
                element={<RetentionStatus />}
              />
              <Route path="/settings/users" element={<UserList />} />
              <Route path="/settings/roles" element={<RoleList />} />
              <Route path="/settings/bonus" element={<BonusPolicy />} />
              <Route path="/settings/affiliate" element={<Affiliate />} />
              <Route
                path="/settings/software-suspend-status"
                element={<SoftwareSuspendStatus />}
              />
              <Route
                path="/settings/reason-for-dropout-status"
                element={<ReasonForDropoutStatus />}
              />
              <Route
                path="/settings/package-of-interest-status-for-stm"
                element={<PackageOfInterestStatusForStm />}
              />
              <Route
                path="/settings/old-package-of-interest-status-for-stm"
                element={<OldPackageOfInterestStatusForStm />}
              />
              <Route path="/task" element={<Task />} />
              <Route path="*" element={<NotFound />} />
            </>
          )}

          {roleName === "SUCCESS_TEAM" && (
            <>
              <Route path="/learners" element={<LearnerList />} />
              <Route path="/learners/detail/:id" element={<LearnerDetail />} />
              <Route
                path="/retention-customers"
                element={<RetentionCustomerList />}
              />
              <Route
                path="/customers/detail/:id"
                element={<CustomerDetail />}
              />
              <Route
                path="/stm-suspended-customers"
                element={<StmSuspendedCustomerList />}
              />
              <Route
                path="/dropout-feedback"
                element={<DropoutFeedbackList />}
              />
              <Route path="/users/detail/:id" element={<UserDetail />} />
              <Route path="/task" element={<Task />} />
              <Route path="*" element={<NotFound />} />
            </>
          )}

          {/* Account Manager Manager Rolü için Rotalar */}
          {(roleName === "ACCOUNT_MANAGER_MANAGER" || roleName === "ADMIN") && (
            <>
              <Route path="/settings" element={<Settings />} />
              <Route path="/settings/users" element={<UserList />} />
              <Route path="/settings/acm-status" element={<ACMStatus />} />
              <Route
                path="/settings/reason-for-dropout-status"
                element={<ReasonForDropoutStatus />}
              />
              <Route
                path="/settings/package-of-interest-status-for-acm"
                element={<PackageOfInterestStatusForAcm />}
              />
              <Route
                path="/settings/current-package-of-interest-status-for-acm"
                element={<CurrentPackageOfInterestStatusForAcm />}
              />
              {/* <Route
                path="/suspended-customers"
                element={<SuspendedCustomerList />}
              /> */}
              <Route path="*" element={<NotFound />} />
            </>
          )}

          {/* {(roleName === "ACCOUNT_MANAGER") && (
            <>
              <Route
                path="/suspended-customers"
                element={<SuspendedCustomerList />}
              />
              <Route path="*" element={<NotFound />} />
            </>
          )} */}

          {/* Hi5 ACM Rolü için Rotalar */}
          {(roleName === "HI5_ACM_MANAGER" ||
            roleName === "HI5_ACM_MEMBER" ||
            roleName === "ADMIN") && (
            <>
              <Route path="/hi5-customers" element={<Hi5CustomerList />} />
              <Route
                path="/customers/detail/:id"
                element={<CustomerDetail />}
              />
              <Route
                path="/dropout-feedback"
                element={<DropoutFeedbackList />}
              />
              <Route path="/task" element={<Task />} />
              {roleName !== "HI5_ACM_MEMBER" && (
                <>
                  <Route path="/settings" element={<Settings />} />
                  <Route path="/settings/users" element={<UserList />} />
                  <Route
                    path="/settings/hi5-acm-status"
                    element={<Hi5ACMStatus />}
                  />
                  <Route
                    path="/settings/reason-for-dropout-status"
                    element={<ReasonForDropoutStatus />}
                  />
                  <Route
                    path="/settings/package-of-interest-status-for-hi5-acm"
                    element={<PackageOfInterestStatusForHi5Acm />}
                  />
                  <Route
                    path="/settings/current-package-of-interest-status-for-hi5-acm"
                    element={<CurrentPackageOfInterestStatusForHi5Acm />}
                  />
                </>
              )}
              <Route path="*" element={<NotFound />} />
            </>
          )}

          {/* Account Manager Rolü için Genel Rotalar */}
          {(roleName === "ACCOUNT_MANAGER" ||
            roleName === "ACCOUNT_MANAGER_MANAGER" ||
            roleName === "ADMIN" ||
            roleName === "CALL_TEAM_MANAGER" ||
            roleName === "CALL_TEAM") && (
            <>
              <Route path="/customers" element={<CustomerList />} />
              <Route
                path="/customers/detail/:id"
                element={<CustomerDetail />}
              />
              <Route path="/task" element={<Task />} />
              {roleName !== "CALL_TEAM_MANAGER" && roleName !== "CALL_TEAM" && (
                <Route
                  path="/dropout-feedback"
                  element={<DropoutFeedbackList />}
                />
              )}
            </>
          )}

          {roleName === "MARKETING_TEAM_MANAGER" && (
            <>
              <Route path="/customers" element={<CustomerList />} />
              <Route
                path="/customers/detail/:id"
                element={<CustomerDetail />}
              />
              <Route
                path="/prospect-customers"
                element={<ProspectCustomers />}
              />
              <Route
                path="/prospect-customers/detail/:id"
                element={<ProspectCustomerDetail />}
              />
            </>
          )}

          {/* ONLY_SEE_CUSTOMER role için Genel Rotalar */}
          {roleName === "ONLY_SEE_CUSTOMER" && (
            <>
              <Route path="/" element={<Navigate replace to="/customers" />} />
              <Route path="/customers" element={<CustomerList />} />
              <Route
                path="/customers/detail/:id"
                element={<CustomerDetail />}
              />
            </>
          )}

          {/* MAS Rolü için Rotalar */}
          {(roleName === "MAS_TEAM_MANAGER" ||
            roleName === "MAS_TEAM_MEMBER" ||
            roleName === "ADMIN") && (
            <>
              <Route path="/mas-customers" element={<MasCustomerList />} />
              <Route
                path="/mas-customers/detail/:id"
                element={<CustomerDetail />}
              />
              <Route
                path="/mas-customers/account-health/:id"
                element={<CustomerAccountHealth />}
              />
              <Route
                path="/mas-customers/orders/:id"
                element={<CustomerOrders />}
              />
              <Route
                path="/mas-customers/shipments/:id"
                element={<CustomerShipments />}
              />
              <Route
                path="/mas-customers/profit-and-loss/:id"
                element={<CustomerProfitAndLoss />}
              />
              <Route path="/task" element={<Task />} />
              {roleName !== "MAS_TEAM_MEMBER" && (
                <>
                  <Route path="/settings" element={<Settings />} />
                  <Route path="/settings/users" element={<UserList />} />
                  <Route
                    path="/settings/feedback-status"
                    element={<MasStatus />}
                  />
                  <Route
                    path="/settings/feedback-channel"
                    element={<MasStatus />}
                  />
                  <Route
                    path="/settings/checklist-status"
                    element={<MasStatus />}
                  />
                </>
              )}
              <Route path="*" element={<NotFound />} />
            </>
          )}

          {/* Account Management Rolü için Rotalar */}
          {(roleName === "ACCOUNT_MANAGEMENT" ||
            roleName === "ACCOUNT_MANAGEMENT_MEMBER" ||
            roleName === "ADMIN") && (
            <>
              <Route path="/account-management-customers" element={<AccountManagementCustomerList />} />
              <Route
                path="/account-management-customers/detail/:id"
                element={<CustomerDetail />}
              />
              <Route
                path="/account-management-customers/account-health/:id"
                element={<CustomerAccountHealth />}
              />
              <Route
                path="/account-management-customers/orders/:id"
                element={<CustomerOrders />}
              />
              <Route
                path="/account-management-customers/shipments/:id"
                element={<CustomerShipments />}
              />
              <Route
                path="/account-management-customers/profit-and-loss/:id"
                element={<CustomerProfitAndLoss />}
              />
              <Route path="/task" element={<Task />} />
              {roleName !== "ACCOUNT_MANAGEMENT_MEMBER" && (
                <>
                  <Route path="/settings" element={<Settings />} />
                  <Route path="/settings/users" element={<UserList />} />
                  <Route
                    path="/settings/feedback-status"
                    element={<MasStatus />}
                  />
                  <Route
                    path="/settings/feedback-channel"
                    element={<MasStatus />}
                  />
                  <Route
                    path="/settings/checklist-status"
                    element={<MasStatus />}
                  />
                </>
              )}
              <Route path="*" element={<NotFound />} />
            </>
          )}

          {/* Suspend Team için Genel Rotalar */}
          {(roleName === "SUSPEND_TEAM" ||
            roleName === "SUSPEND_TEAM_MANAGER" ||
            roleName === "ADMIN") && (
            <>
              <Route
                path="/amazon-suspended-applicants"
                element={<AmazonSuspendedApplicantList />}
              />
              <Route
                path="/amazon-suspended-applicants/:id"
                element={<AmazonSuspendedApplicantDetail />}
              />
              <Route path="/task" element={<Task />} />
              {roleName !== "SUSPEND_TEAM" && (
                <>
                  <Route
                    path="/settings/amazon-suspended-applicant-status"
                    element={<AmazonSuspendedApplicantStatus />}
                  />
                  <Route
                    path="/settings/amazon-suspended-applicant-suspend-types"
                    element={<AmazonSuspendedApplicantSuspendTypes />}
                  />
                  <Route
                    path="/settings/amazon-suspended-applicant-service-types"
                    element={<AmazonSuspendedApplicantServiceTypes />}
                  />
                  <Route
                    path="/settings/amazon-suspended-applicant-action-types"
                    element={<AmazonSuspendedApplicantActionTypes />}
                  />
                  <Route
                    path="/settings/amazon-suspended-applicant-inputs"
                    element={<AmazonSuspendedApplicantInputs />}
                  />
                  <Route path="/settings/users" element={<UserList />} />
                </>
              )}
            </>
          )}

          {(roleName === "CALL_TEAM_MANAGER" ||
            roleName === "SUCCESS_TEAM_MANAGER" ||
            roleName === "ACCOUNT_MANAGER_MANAGER" ||
            roleName === "HI5_ACM_MANAGER" ||
            roleName === "SUSPEND_TEAM_MANAGER" ||
            roleName === "MAS_TEAM_MANAGER" ||
            roleName === "ACCOUNT_MANAGEMENT" ||
            roleName === "MARKETING_TEAM_MANAGER" ||
            roleName === "ADMIN") && (
            <>
              <Route
                path="/prospect-customers/sales-approval"
                element={<SalesApproval />}
              />
              <Route
                path="/customers/sales-approval"
                element={<SalesApproval />}
              />
              <Route
                path="/hi5-customers/sales-approval"
                element={<SalesApproval />}
              />
              <Route
                path="/learners/sales-approval"
                element={<SalesApproval />}
              />
              <Route
                path="/settings/call-sales-package"
                element={<CallSalesPackage />}
              />
              <Route path="/reports">
                <Route index element={<Reports />} />
                {roleName === "ACCOUNT_MANAGER_MANAGER" ||
                roleName === "ADMIN" ? (
                  <>
                    <Route path="acm-reports/call-history" element={<Call />} />
                    <Route path="acm-reports/status" element={<Status />} />
                    <Route
                      path="acm-reports/current-packages"
                      element={<CurrentPackage />}
                    />
                    <Route
                      path="acm-reports/earnings-report"
                      element={<EarningsReport />}
                    />
                    <Route
                      path="acm-reports/sales-log-report"
                      element={<SalesLogReport />}
                    />
                    <Route
                      path="acm-reports/summary-report"
                      element={<SummaryReport />}
                    />
                    <Route
                      path="acm-reports/package-of-interest-for-acm"
                      element={<InterestReport />}
                    />
                    <Route
                      path="acm-reports/current-package-of-interest-for-acm"
                      element={<InterestReport />}
                    />
                  </>
                ) : null}
                {roleName === "HI5_ACM_MANAGER" || roleName === "ADMIN" ? (
                  <>
                    <Route
                      path="hi5-acm-reports/call-history"
                      element={<Hi5Call />}
                    />
                    <Route
                      path="hi5-acm-reports/status"
                      element={<Hi5Status />}
                    />
                    <Route
                      path="hi5-acm-reports/current-packages"
                      element={<Hi5CurrentPackage />}
                    />
                    <Route
                      path="hi5-acm-reports/earnings-report"
                      element={<EarningsReport />}
                    />
                    <Route
                      path="hi5-acm-reports/sales-log-report"
                      element={<SalesLogReport />}
                    />
                    <Route
                      path="hi5-acm-reports/summary-report"
                      element={<SummaryReport />}
                    />
                    <Route
                      path="hi5-acm-reports/package-of-interest-for-hi5-acm"
                      element={<InterestReport />}
                    />
                    <Route
                      path="hi5-acm-reports/current-package-of-interest-for-hi5-acm"
                      element={<InterestReport />}
                    />
                    <Route
                      path="hi5-acm-reports/lessons"
                      element={<Hi5LessonsReport />}
                    />
                  </>
                ) : null}
                {roleName === "SUCCESS_TEAM_MANAGER" || roleName === "ADMIN" ? (
                  <>
                    <Route
                      path="stm-reports/learner-status"
                      element={<StmStatusReport />}
                    />
                    <Route
                      path="stm-reports/retention-status"
                      element={<StmStatusReport />}
                    />
                    <Route
                      path="stm-reports/software-suspended-status"
                      element={<StmSoftwareSuspendedStatusReport />}
                    />
                    <Route
                      path="stm-reports/education"
                      element={<StmEducationReport />}
                    />
                    <Route
                      path="stm-reports/success-team"
                      element={<StmSuccessTeamReport />}
                    />
                    <Route
                      path="stm-reports/call-history"
                      element={<StmCallHistoryReport />}
                    />
                    <Route
                      path="stm-reports/affiliate"
                      element={<StmAffiliateReport />}
                    />
                    <Route
                      path="stm-reports/earnings-report"
                      element={<EarningsReport />}
                    />
                    <Route
                      path="stm-reports/sales-log-report"
                      element={<SalesLogReport />}
                    />
                    <Route
                      path="stm-reports/summary-report"
                      element={<SummaryReport />}
                    />
                    <Route
                      path="stm-reports/package-of-interest-for-stm"
                      element={<InterestReport />}
                    />
                    <Route
                      path="stm-reports/old-package-of-interest-for-stm"
                      element={<InterestReport />}
                    />
                  </>
                ) : null}
                {roleName === "CALL_TEAM_MANAGER" || roleName === "ADMIN" ? (
                  <>
                    <Route
                      path="ctm-reports/call-history"
                      element={<CtmCallHistoryReport />}
                    />
                    <Route
                      path="ctm-reports/earnings-report"
                      element={<EarningsReport />}
                    />
                    <Route
                      path="ctm-reports/sales-log-report"
                      element={<SalesLogReport />}
                    />
                    <Route
                      path="ctm-reports/summary-report"
                      element={<CtmSummaryReport />}
                    />
                  </>
                ) : null}
                {roleName === "SUSPEND_TEAM_MANAGER" || roleName === "ADMIN" ? (
                  <>
                    <Route
                      path="suspendtm-reports/amazon-suspended-applicant-reports"
                      element={<AmazonSuspendedApplicantReports />}
                    />
                    <Route
                      path="suspendtm-reports/amazon-suspended-applicant-status-report"
                      element={
                        <SuspendTmAmazonSuspendedApplicantStatusReport />
                      }
                    />
                  </>
                ) : null}
                {roleName === "MAS_TEAM_MANAGER" || roleName === "ADMIN" ? (
                  <>
                    <Route
                      path="mastm-reports/summary-report"
                      element={<MasTmSummaryReport />}
                    />
                  </>
                ) : null}
                {roleName === "ACCOUNT_MANAGEMENT" || roleName === "ADMIN" ? (
                  <>
                    <Route
                      path="account-management-reports/summary-report"
                      element={<MasTmSummaryReport />}
                    />
                  </>
                ) : null}
                {roleName === "MARKETING_TEAM_MANAGER" ||
                roleName === "ADMIN" ? (
                  <>
                    <Route
                      path="marketingtm-reports/summary-report"
                      element={<MarketingTmSummaryReport />}
                    />
                  </>
                ) : null}
                <Route
                  path="common-reports/feedback-report"
                  element={<FeedbackReport />}
                />
              </Route>
            </>
          )}

          {/* Admin Rolü için Ekstra Rotalar */}
          {(roleName === "ADMIN" || roleName === "CALL_TEAM_MANAGER") && (
            <>
              <Route
                path="/settings/departments"
                element={<DepartmentList />}
              />
              <Route path="/settings/employees" element={<EmployeeList />} />
            </>
          )}

          {/* Genel Rotalar (Tüm Roller İçin) */}
          <Route path="/employees" element={<EmployeesList />} />
          <Route path="/time-off-schedule" element={<TimeOffSchedule />} />
          <Route path="/" element={<Dashboard />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route
          path="/login"
          element={
            <Login setIsLoggedIn={setIsLoggedIn} isLoggedIn={isLoggedIn} />
          }
        />
        <Route
          path="/verify-external-token"
          element={<VerifyExternalToken />}
        />
        <Route path="/feedback" element={<Feedback />} />
      </Routes>
    </Router>
  );
};

export default App;
