import {
  Button,
  Modal,
  Form,
  Select,
  Row,
  Col,
  Tooltip,
  DatePicker,
} from "antd";
import React, { useState } from "react";
import filterIcon from "../../assets/filter.svg";
import StatusService from "../../services/StatusService";

const { Item } = Form;

interface CustomerFilterProps {
  onChangeFilter: (values: any) => void;
  setFormFields: any;
  formFields: any;
}

const Filter: React.FC<CustomerFilterProps> = ({
  onChangeFilter,
  setFormFields,
  formFields,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reasonForDropoutStatusOptions, setReasonForDropoutStatusOptions] =
    useState<any[]>([]);
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();

  const showModal = () => {
    if (reasonForDropoutStatusOptions.length === 0) {
      getReasonForDropoutStatus();
    }
    setIsModalOpen(true);
  };

  const onFinish = (values: any) => {
    onChangeFilter(values);
    setFormFields(form.getFieldsValue());
    setIsModalOpen(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const clearForm = () => {
    form.resetFields();
    setFormFields(form.getFieldsValue());
  };

  const getReasonForDropoutStatus = () => {
    let mainStatuses: any[] = [];
    let subStatuses: any[] = [];
    StatusService.getAll(
      "statusType=CUSTOMER_ASSIGNMENT_REASON_STATUS",
      `&active=true`
    )
      .then((response: any) => {
        if (response.data) {
          let data = response.data;
          data.forEach((item: any) => {
            if (item.parent === null) {
              mainStatuses.push(item);
            } else {
              subStatuses.push(item);
            }
          });

          let processedData = mainStatuses.map((mainStatus: any) => {
            return {
              ...mainStatus,
              subStatuses: subStatuses.filter(
                (subStatus: any) => subStatus.parent.id === mainStatus.id
              ),
            };
          });
          setReasonForDropoutStatusOptions(processedData);
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const oldMemberFlagOptions = [
    { value: "ACCOUNT_MANAGER", label: "Account Manager" },
    { value: "SUCCESS_TEAM", label: "Success Team" },
    {
      value: "SUCCESS_TEAM_SOFTWARE_SUSPEND",
      label: "Success Team Software Suspend",
    },
    { value: "HI5_ACM_MEMBER", label: "Hi5 ACM Member" },
    { value: "MAS_TEAM_MEMBER", label: "Mas Team Member" },
    { value: "LEARNER_INTEGRATION", label: "Learner Integration" },
    { value: "NEW", label: "New" },
  ];

  const newMemberFlagOptions = [
    { value: "ACCOUNT_MANAGER", label: "Account Manager" },
    { value: "SUCCESS_TEAM", label: "Success Team" },
    {
      value: "SUCCESS_TEAM_SOFTWARE_SUSPEND",
      label: "Success Team Software Suspend",
    },
    { value: "HI5_ACM_MEMBER", label: "Hi5 ACM Member" },
    { value: "MAS_TEAM_MEMBER", label: "MAS Team Member" },
  ];

  return (
    <>
      <Modal
        title="Customer Filter"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Row justify="space-between" align="middle" gutter={[8, 8]}>
            <Col xs={24} sm={12}>
              Old Location
              <Item name="oldMemberFlag">
                <Select placeholder={"Select a old location"}>
                  <Select.Option key={"null-old-location"} value={null}>
                    Select a old location
                  </Select.Option>
                  {oldMemberFlagOptions?.map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col xs={24} sm={12}>
              New Location
              <Item name="newMemberFlag">
                <Select placeholder={"Select a new location"}>
                  <Select.Option key={"null-new-location"} value={null}>
                    Select a new location
                  </Select.Option>
                  {newMemberFlagOptions?.map((option: any) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
          </Row>
          <Row justify="space-between" align="middle" gutter={[8, 8]}>
            <Col xs={24} sm={12}>
              Reason for Dropout
              <Item name="reasonForDropoutStatus">
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option && option.label && typeof option.label === "string"
                      ? option.label.toLowerCase().includes(input.toLowerCase())
                      : false
                  }
                  placeholder={"Select a sub reason for dropout"}
                  style={{ width: "100%" }}
                >
                  {reasonForDropoutStatusOptions.map((mainStatus) => (
                    <Select.OptGroup
                      key={mainStatus.id}
                      label={mainStatus.name}
                    >
                      {mainStatus.subStatuses.map((subStatus: any) => (
                        <Select.Option key={subStatus.id} value={subStatus.id}>
                          {subStatus.name}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col xs={24} sm={12}>
              Assignment Date
              <Item name="assignmentDateRange">
                <RangePicker format="DD/MM/YYYY" style={{ width: "100%" }} />
              </Item>
            </Col>
          </Row>
          <Item>
            <Row justify="end">
              <Button
                key="back"
                style={{ marginRight: "10px" }}
                onClick={clearForm}
              >
                Clear
              </Button>
              <Button htmlType="submit" type="primary">
                Apply
              </Button>
            </Row>
          </Item>
        </Form>
      </Modal>
      <Tooltip title="Filters">
        <Button className="mr-15" onClick={showModal} type="default">
          <img src={filterIcon} alt="userIcon" style={{ margin: "1px" }} />
        </Button>
      </Tooltip>
    </>
  );
};

export default Filter;
