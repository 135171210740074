import React, { useState, useEffect } from "react";
import { Card, Col, Row, Table } from "antd";
import ReportService from "../../services/ReportService";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";

interface LearnerCourseHistoryCardProps {
  id: number;
}

const LearnerCourseHistoryCard: React.FC<
  LearnerCourseHistoryCardProps
> = ({
  id
}) => {
  const [courseHistory, setCourseHistory] = useState<any[]>([]);

  const getLearnerCourseHistory = () => {
    if (id) {
      ReportService.getLearnerCourseHistory(id, '?startDate=2000-01-01T00:00:00&endDate=2050-12-31T23:59:59')
        .then((response) => {
          setCourseHistory(response.data);
        })
        .catch((error: Error) => {
          console.error(error);
        });
    }
  };
  useEffect(() => {
    getLearnerCourseHistory();
    // eslint-disable-next-line
  }, [id]);

  const columns: ColumnsType<any> = [
      {
        title: <span style={{ fontSize: 12 }}>Course Name</span>,
        dataIndex: "courseName",
        key: "courseName",
        ellipsis: true,
        align: "left",
        width: 100,
        sorter: (a: any, b: any) => a.courseName.localeCompare(b.courseName),
        render: (_: any, row: any) => {
          return <b style={{ fontSize: 12 }}>{row?.courseName}</b>;
        },
      },
      {
        title: <span style={{ fontSize: 12 }}>Lesson Name</span>,
        dataIndex: "lessonName",
        key: "lessonName",
        align: "left",
        ellipsis: true,
        width: 90,
        sorter: (a: any, b: any) => a.lessonName.localeCompare(b.lessonName),
        render: (text: any) => <b style={{ fontSize: 12 }}>{text}</b>,
      },
      {
        title: <span style={{ fontSize: 12 }}>Start Date</span>,
        dataIndex: "startedDate",
        key: "startedDate",
        align: "center",
        width: 90,
        sorter: (a: any, b: any) => {
          if (!a.startedDate) return -1;
          if (!b.startedDate) return 1;
          return dayjs(a.startedDate).valueOf() - dayjs(b.startedDate).valueOf();
        },        
        render: (text: any) =>
          <span style={{ fontSize: 12 }}>{text ? dayjs(text).format("MM/DD/YYYY HH:mm:ss") : "N/A"}</span>,
      },
      {
        title: <span style={{ fontSize: 12 }}>Finish Date</span>,
        dataIndex: "finishedDate",
        key: "finishedDate",
        align: "center",
        width: 90,
        sorter: (a: any, b: any) => {
          if (!a.finishedDate) return -1;
          if (!b.finishedDate) return 1;
          return dayjs(a.finishedDate).valueOf() - dayjs(b.finishedDate).valueOf();
        }, 
        render: (text: any) =>
          <span style={{ fontSize: 12 }}>{text ? dayjs(text).format("MM/DD/YYYY HH:mm:ss") : "N/A"}</span>,
      },
    ];

  return (
    <Card bordered={false} className={"sales-card"}>
      <p className="title">Course History</p>
      <Row style={{ marginTop: "24px", height: "265px" }}>
        <Col span={24}>
          <Table
            rowKey={(record: any) => record?.id}
            columns={columns}
            dataSource={courseHistory}
            pagination={false}
            scroll={{ x: "100%", y: 230 }}
            size="small"
            bordered
            className="dashboard-table"
          />
        </Col>
      </Row>
    </Card>
  );
};

export default LearnerCourseHistoryCard;
