import { Breadcrumb, Card, Col, Collapse, Row, Select, Typography } from "antd";
import { NavLink } from "react-router-dom";
import { ReportsRouter } from "../../ReportsRouter";
import DateRange from "../../../Common/DateRange";
import { useState } from "react";
import MasTmAutoAndPreAutoPilotReport from "./MasTmAutoAndPreAutoPilotReport";

const { Title } = Typography;
const { Panel } = Collapse;

const reportPanels = [
  {
    key: "1",
    header: "Auto and Pre-Auto Pilot Report",
    component: MasTmAutoAndPreAutoPilotReport,
  },
];

const MasTmSummaryReport = () => {
  const [dateRange, setDateRange] = useState<[string, string]>(["", ""]);
  const [periodCount, setPeriodCount] = useState<number>(3);
  const [loadedPanels, setLoadedPanels] = useState<string[]>([]);
  const [activePanels, setActivePanels] = useState<string[]>([]);

  const handlePanelChange = (key: string | string[]) => {
    const keyArray = Array.isArray(key) ? key : [key];
    setActivePanels(keyArray);
    setLoadedPanels((prev) => Array.from(new Set([...prev, ...keyArray])));
  };

  return (
    <>
      <Row>
        <Col>
          <Title className="onemaz-page-title" level={3}>
            Reports
          </Title>
          <Breadcrumb
            items={[
              {
                title: <NavLink to="/">Home</NavLink>,
              },
              {
                title: "Summary Report",
              },
            ]}
          />
        </Col>
      </Row>
      <ReportsRouter />
      <Card
        className="card-customers zebra-table"
        title={
          <>
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <div
                className="customer-card-title"
                style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
              >
                Summary Report
              </div>
            </div>
          </>
        }
        extra={
          <Row justify={"end"}>
            <DateRange thisMonth={true} onChangeDateRange={setDateRange} />
            <Select
              defaultValue={3}
              style={{ width: 150, marginLeft: 10 }}
              onChange={(value) => setPeriodCount(value)}
              size="large"
            >
              <Select.Option value={1}>Last 1 period</Select.Option>
              <Select.Option value={2}>Last 2 period</Select.Option>
              <Select.Option value={3}>Last 3 period</Select.Option>
              <Select.Option value={4}>Last 4 period</Select.Option>
              <Select.Option value={5}>Last 5 period</Select.Option>
              <Select.Option value={6}>Last 6 period</Select.Option>
            </Select>
          </Row>
        }
        bordered
        style={{ marginTop: "30px" }}
      >
        <Collapse
          onChange={handlePanelChange}
          activeKey={activePanels}
          expandIconPosition="end"
        >
          {reportPanels.map(({ key, header, component: Component }) => (
            <Panel key={key} header={<h3 style={{ margin: 0 }}>{header}</h3>}>
              {loadedPanels.includes(key) && (
                <Component dateRange={dateRange} periodCount={periodCount} />
              )}
            </Panel>
          ))}
        </Collapse>
      </Card>
    </>
  );
};

export default MasTmSummaryReport;
