import http from "../http-common";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ICustomerRequestData } from "../types/Customer";

const getAll = (filteredDate: string = "") => {
  return http.get<any>(`/customers${filteredDate}`);
};

const getAllMe = (filteredDate: string = "") => {
  return http.get<any>(`/users/me/customers${filteredDate}`);
};

const get = (id: any) => {
  return http.get<any>(`/customers/${id}`);
};

const getAllWithoutPagination = (filterCriteria: string = "") => {
  return http.get<Array<any>>(`/customers/all${filterCriteria}`);
};

const getAllWithoutPaginationMe = (filterCriteria: string = "") => {
  return http.get<Array<any>>(`/users/me/customers/all${filterCriteria}`);
};

const update = (id: any, data: any) => {
  return http.put<any>(`/customers/${id}/status`, data);
};

const updateACMStatus = (id: any, data: any) => {
  return http.put<any>(`/customers/${id}/acm-status`, data);
};

const updateHi5ACMStatus = (id: any, data: any) => {
  return http.put<any>(`/customers/${id}/hi5-acm-status`, data);
};

const updateMasFeedbackStatus = (id: any, data: any) => {
  return http.put<any>(`/customers/${id}/mas-feedback-status`, data);
};

const updateMasFeedbackChannel = (id: any, data: any) => {
  return http.put<any>(`/customers/${id}/mas-feedback-channel`, data);
};

const updateMasSuspendTeamDepartmentStatus = (id: number, data: any) => {
  return http.put<any>(`/customers/${id}/mas-suspend-team`, data);
};

const getAllStatus = (query = "") => {
  return http.get<Array<any>>(`customers/statuses${query}`);
};

const updateAssigneUser = (id: any, data: any) => {
  const roleName = localStorage.getItem("roleName") || "";
  if (roleName === "SUCCESS_TEAM_MANAGER") {
    return http.patch<any>(`/customers/${id}/success-team-member`, data);
  } else if (roleName === "HI5_ACM_MANAGER") {
    return http.patch<any>(`/customers/${id}/hi-acm-member`, data);
  } else if (roleName === "MAS_TEAM_MANAGER") {
    return http.patch<any>(`/customers/${id}/mas-team-member`, data);
  } else {
    return http.patch<any>(`/customers/${id}/users`, data);
  }
};

const getSalesCountLastWeek = (filteredDate: string = "") => {
  return http.get<any>(`/customers/sales/daily/count${filteredDate}`);
};

const getSalesCountLastWeekMe = (filteredDate: string = "") => {
  return http.get<any>(`/users/me/sales/daily/count${filteredDate}`);
};

const sendFeedbackRequest = (data: any) => {
  return http.post<any>(`/customers/feedback`, data);
};

const getLearnerDetail = (oneamzAppId: any) => {
  return http.get<any>(`/customers/${oneamzAppId}/learners`);
};

const getAllCustomersLite = (filteredDate: string = "") => {
  return http.get<Array<any>>(`/customers/all-lite${filteredDate}`);
};

const getAllCustomersLiteMe = (filteredDate: string = "") => {
  return http.get<Array<any>>(`/users/me/customers/all-lite${filteredDate}`);
};

const updateMultiStatus = (data: any) => {
  return http.put<any>(`/customers/status`, data);
};

const getStoreInformation = (id: any) => {
  return http.get<any>(`/customers/${id}/stores/data`);
};

const assignMulti = (data: any) => {
  const currentPath = window.location.pathname;
  const roleName = localStorage.getItem("roleName") || "";
  if (roleName === "SUCCESS_TEAM_MANAGER" && currentPath.includes("/stm-suspended-customers")) {
    return http.patch<any>(`/customers/success-team-member/software-suspend`, data);
  } else if (roleName === "SUCCESS_TEAM_MANAGER") {
    return http.patch<any>(`/customers/success-team-member`, data);
  } else if (roleName === "HI5_ACM_MANAGER") {
    return http.patch<any>(`/customers/hi-acm-member`, data);
  } else {
    return http.patch<any>(`/customers/users`, data);
  }
};

const getSalesPackages = (id: any) => {
  return http.get<any>(`/customers/${id}/sales-packages`);
};

const getCustomerStatistics = (criteria: any) => {
  return http.get(`/customer-statistics/daily/report${criteria}`);
};

const getMeCustomerStatistics = (criteria: any) => {
  return http.get(`/me/learner-statistics/daily/report${criteria}`);
};

const assignCustomerToSoftwareSuspendStatus = (data: any) => {
  return http.put<any>(`/customers/suspend-status`, data);
};

const assignCustomerToPackageOfInterestStatusForStm = (
  id: number,
  data: any
) => {
  return http.put<any>(`/customers/${id}/package-of-interest-stm`, data);
};

const assignCustomerToPackageOfInterestStatusForAcm = (
  id: number,
  data: any
) => {
  return http.put<any>(`/customers/${id}/package-of-interest-acm`, data);
};

const assignCustomerToPackageOfInterestStatusForHi5Acm = (
  id: number,
  data: any
) => {
  return http.put<any>(`/customers/${id}/package-of-interest-hi5acm`, data);
};

const assignCustomerToOldPackageOfInterestStatusForStm = (
  id: number,
  data: any
) => {
  return http.put<any>(`/customers/${id}/old-package-interest-stm`, data);
};

const assignCustomerToCurrentPackageOfInterestStatusForAcm = (
  id: number,
  data: any
) => {
  return http.put<any>(
    `/customers/${id}/current-package-of-interest-acm`,
    data
  );
};

const assignCustomerToCurrentPackageOfInterestStatusForHi5Acm = (
  id: number,
  data: any
) => {
  return http.put<any>(
    `/customers/${id}/current-package-of-interest-hi5acm`,
    data
  );
};

const updateContact = (id: number, data: any) => {
  return http.patch<any>(`/customers/${id}/update-contact`, data);
};

const getMasCustomerDashboard = (id: number, filterCriteria: string = "") => {
  return http.get<any>(`/customers/${id}/dashboard${filterCriteria}`);
}

const getMasCustomerAccountHealth = (id: number) => {
  return http.get<any>(`/customers/${id}/account-health`);
}

const getMasCustomerOrders = (id: number) => {
  return http.get<any>(`/customers/${id}/orders`);
}

const getMasCustomerShipments = (id: number) => {
  return http.get<any>(`/customers/${id}/shipments`);
}

const getMasCustomerProfitAndLoss = (id: number, filterCriteria: string = "") => {
  return http.get<any>(`/customers/${id}/customer-order-profit-report${filterCriteria}`);
}

const updateMasCustomerProfitAndLoss = (id: number, data: any) => {
  return http.put<any>(`/customers/${id}/customer-order-profit-report`, data);
}

const updateByPackage = (id: number) => {
  return http.put<any>(`/customers/${id}/update-by-packages`);  
}

const CustomerService = {
  getAll,
  getAllMe,
  get,
  getAllWithoutPagination,
  getAllWithoutPaginationMe,
  update,
  updateACMStatus,
  updateHi5ACMStatus,
  updateMasFeedbackStatus,
  updateMasFeedbackChannel,
  updateMasSuspendTeamDepartmentStatus,
  getAllStatus,
  updateAssigneUser,
  getSalesCountLastWeek,
  getSalesCountLastWeekMe,
  sendFeedbackRequest,
  getLearnerDetail,
  getAllCustomersLite,
  getAllCustomersLiteMe,
  updateMultiStatus,
  getStoreInformation,
  assignMulti,
  getSalesPackages,
  getMeCustomerStatistics,
  getCustomerStatistics,
  assignCustomerToSoftwareSuspendStatus,
  assignCustomerToPackageOfInterestStatusForStm,
  assignCustomerToPackageOfInterestStatusForAcm,
  assignCustomerToPackageOfInterestStatusForHi5Acm,
  assignCustomerToOldPackageOfInterestStatusForStm,
  assignCustomerToCurrentPackageOfInterestStatusForAcm,
  assignCustomerToCurrentPackageOfInterestStatusForHi5Acm,
  updateContact,
  getMasCustomerDashboard,
  getMasCustomerAccountHealth,
  getMasCustomerOrders,
  getMasCustomerShipments,
  getMasCustomerProfitAndLoss,
  updateMasCustomerProfitAndLoss,
  updateByPackage
};

export default CustomerService;
