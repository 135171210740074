import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { useEffect, useRef, useState } from "react";
import MarketingReportService from "../../../../services/MarketingReportService";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import ReusableChart from "../../CommonReport/SummaryReport/ReusableChart";
import { openNotification } from "../../../../utils";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { enGB } from "date-fns/locale/en-GB";
import { CalendarOutlined } from "@ant-design/icons";

dayjs.extend(isoWeek);
registerLocale("en-GB", enGB);

const { Title } = Typography;

const MarketingTmVisitorAndExpensesReport = ({
  dateRange,
  periodCount,
}: {
  dateRange: any;
  periodCount: number;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [dateLabels, setDateLabels] = useState<any[]>([]);
  const [leadCostSeries, setLeadCostSeries] = useState<any[]>([]);
  const [totalExpensesSeries, setTotalExpensesSeries] = useState<any[]>([]);
  const [leadCountSeries, setLeadCountSeries] = useState<any[]>([]);
  const [salesPercentageSeries, setSalesPercentageSeries] = useState<any[]>([]);
  const [chartHeight, setChartHeight] = useState<number>(200);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedWeek, setSelectedWeek] = useState<Date | null>(null);
  const [form] = Form.useForm();
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1200 && tableRef.current) {
        const tableColHeight = tableRef.current.getBoundingClientRect().height;
        setChartHeight(Math.max(200, tableColHeight - 30));
      } else {
        setChartHeight(250);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, [dataSource]);

  const handleModalOpen = () => {
    form.resetFields();
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleDateChange = async (date: Date | null) => {
    setSelectedWeek(date);

    if (!date) {
      form.resetFields();
      return;
    }

    // Seçilen tarihi dayjs nesnesine çevir
    const dateDayjs = dayjs(date);

    const startDate = dateDayjs.startOf("isoWeek").format("YYYY-MM-DD");
    const endDate = dateDayjs.endOf("isoWeek").format("YYYY-MM-DD");

    try {
      const response = await MarketingReportService.getVisitorAndExpenses(
        startDate,
        endDate
      );
      if (response.data && response.data.length > 0) {
        form.setFieldsValue({
          weeklyVisitors: response.data[0].weeklyVisitors,
          totalExpenses: response.data[0].totalExpenses,
        });
      } else {
        form.resetFields(["weeklyVisitors", "totalExpenses"]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleFormSubmit = async (values: any) => {
    setLoading(true);
    try {
      // Formdan gelen tarih değerini dayjs'e çevir (bu değer react-datepicker ile alındıktan sonra set edilecek)
      const dateDayjs = dayjs(values.date);
      const startDate = dateDayjs.startOf("isoWeek").format("YYYY-MM-DD");
      const endDate = dateDayjs.endOf("isoWeek").format("YYYY-MM-DD");

      const existingData = await MarketingReportService.getVisitorAndExpenses(
        startDate,
        endDate
      );

      if (existingData.data.length > 0) {
        // Veri varsa PUT işlemi
        const request = {
          id: existingData.data[0].id,
          weeklyVisitors: values.weeklyVisitors,
          totalExpenses: values.totalExpenses,
        };

        await MarketingReportService.updateVisitorAndExpenses(
          request,
          existingData.data[0].id
        );
        openNotification("success", "Success", "Data updated successfully.");
      } else {
        // Veri yoksa POST işlemi
        const request = {
          reportStartDate: startDate + "T00:00:00.000Z",
          reportEndDate: endDate + "T23:59:59.999Z",
          weeklyVisitors: values.weeklyVisitors,
          totalExpenses: values.totalExpenses,
        };

        await MarketingReportService.createVisitorAndExpenses(request);
        openNotification("success", "Success", "Data added successfully.");
      }

      handleModalClose();
      const periods = getPeriods(dateRange, periodCount);
      await getReports(periods);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getPeriods = (dateRange: any, periodCount: number) => {
    const periods = [];
    let start = dayjs(dateRange[0]);
    let end = dayjs(dateRange[1]);

    for (let i = 0; i < periodCount; i++) {
      const formattedStart = start.format("YYYY-MM-DD");
      const formattedEnd = end.format("YYYY-MM-DD");

      periods.push({
        start: formattedStart,
        end: formattedEnd,
        label: `${start.format("DD.MMM.YY")} - ${end.format("DD.MMM.YY")}`,
      });

      const diffDays = end.diff(start, "days") + 1;
      end = start.subtract(1, "days");
      start = end.subtract(diffDays - 1, "days");
    }

    return periods.reverse();
  };

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const periods = getPeriods(dateRange, periodCount);
      getReports(periods);
      setDateLabels(periods);
    }
    // eslint-disable-next-line
  }, [dateRange, periodCount]);

  const getReports = async (periods: any[]) => {
    setLoading(true);
    try {
      const promises = periods.map((period) =>
        MarketingReportService.getMarketingReport(period.start, period.end)
      );

      const responses = await Promise.all(promises);

      const formattedData = responses.map((response, index) => {
        // Gelen diziyi işleyip değerleri topla
        const data = response.data || [];

        const combinedData = data.reduce(
          (acc, current) => {
            acc.weeklyVisitors += current.weeklyVisitors || 0;
            acc.weeklyLeadCount += current.weeklyLeadCount || 0;
            acc.unreachableCount += current.unreachableCount || 0;
            acc.totalSales += current.totalSales || 0;
            acc.totalExpenses += current.totalExpenses || 0;
            return acc;
          },
          {
            weeklyVisitors: 0,
            weeklyLeadCount: 0,
            unreachableCount: 0,
            totalSales: 0,
            totalExpenses: 0,
          }
        );

        // Ortalama hesaplanması gereken yüzdelik değerleri hesapla
        const unreachablePercentage =
          combinedData.weeklyLeadCount > 0
            ? (combinedData.unreachableCount / combinedData.weeklyLeadCount) *
              100
            : 0;

        const salesPercentage =
          combinedData.weeklyLeadCount > 0
            ? (combinedData.totalSales / combinedData.weeklyLeadCount) * 100
            : 0;

        const leadCostPerLead =
          combinedData.weeklyLeadCount > 0
            ? combinedData.totalExpenses / combinedData.weeklyLeadCount
            : 0;

        return {
          key: index,
          date: periods[index].label,
          weeklyVisitors: combinedData.weeklyVisitors,
          leadCostPerLead: parseFloat(leadCostPerLead.toFixed(2)),
          weeklyLeadCount: combinedData.weeklyLeadCount,
          unreachableCount: combinedData.unreachableCount,
          unreachablePercentage: parseFloat(unreachablePercentage.toFixed(2)),
          totalSales: parseFloat(combinedData.totalSales.toFixed(2)),
          totalExpenses: parseFloat(combinedData.totalExpenses.toFixed(2)),
          salesPercentage: parseFloat(salesPercentage.toFixed(2)),
        };
      });

      setDataSource(formattedData);

      // Grafik için verileri ayarla
      setLeadCostSeries([
        {
          name: "Cost Per Lead",
          data: formattedData.map((item) =>
            parseFloat(item.leadCostPerLead.toFixed(2))
          ),
        },
      ]);

      setTotalExpensesSeries([
        {
          name: "Total Expenses",
          data: formattedData.map((item) =>
            parseFloat(item.totalExpenses.toFixed(2))
          ),
        },
      ]);

      setLeadCountSeries([
        {
          name: "Lead Count",
          data: formattedData.map((item) => item.weeklyLeadCount),
        },
      ]);

      setSalesPercentageSeries([
        {
          name: "Sales Percentage",
          data: formattedData.map((item) =>
            parseFloat(item.salesPercentage.toFixed(2))
          ),
        },
      ]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: "Period",
      dataIndex: "date",
      key: "date",
      align: "center",
      width: 180,
      fixed: "left",
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) =>
        new Date(a.date).getTime() - new Date(b.date).getTime(),
      render: (date: string) => <b>{date ? date : "-"}</b>,
    },
    {
      title: "Visitors Count",
      dataIndex: "weeklyVisitors",
      key: "weeklyVisitors",
      align: "center",
      width: 120,
      sorter: (a: any, b: any) => a?.weeklyVisitors - b?.weeklyVisitors,
      render: (text: any) => <b>{text ? text : "0"}</b>,
    },
    {
      title: "Cost Per Lead",
      dataIndex: "leadCostPerLead",
      key: "leadCostPerLead",
      align: "center",
      width: 120,
      sorter: (a: any, b: any) => a?.leadCostPerLead - b?.leadCostPerLead,
      render: (text: any) => <b>${parseFloat(text).toFixed(2)}</b>,
    },
    {
      title: "Lead Count",
      dataIndex: "weeklyLeadCount",
      key: "weeklyLeadCount",
      align: "center",
      width: 120,
      sorter: (a: any, b: any) => a?.weeklyLeadCount - b?.weeklyLeadCount,
      render: (text: any) => <b>{text ? text : "0"}</b>,
    },
    {
      title: "Number of Unreachable",
      dataIndex: "unreachableCount",
      key: "unreachableCount",
      align: "center",
      width: 120,
      sorter: (a: any, b: any) => a?.unreachableCount - b?.unreachableCount,
      render: (text: any) => <b>{text ? text : "0"}</b>,
    },
    {
      title: "Unreachable Percentage",
      dataIndex: "unreachablePercentage",
      key: "unreachablePercentage",
      align: "center",
      width: 120,
      sorter: (a: any, b: any) =>
        a?.unreachablePercentage - b?.unreachablePercentage,
      render: (text: any) => <b>{text ? text.toFixed(2) : "0.00"}%</b>,
    },
    {
      title: "Total Sales",
      dataIndex: "totalSales",
      key: "totalSales",
      align: "center",
      width: 120,
      sorter: (a: any, b: any) => a?.totalSales - b?.totalSales,
      render: (text: any) => <b>{text ? text : "0"}</b>,
    },
    {
      title: "Total Expenses",
      dataIndex: "totalExpenses",
      key: "totalExpenses",
      align: "center",
      width: 120,
      sorter: (a: any, b: any) => a?.totalExpenses - b?.totalExpenses,
      render: (text: any) => <b>${parseFloat(text).toFixed(2)}</b>,
    },
    {
      title: "Sales Percentage",
      dataIndex: "salesPercentage",
      key: "salesPercentage",
      align: "center",
      width: 120,
      sorter: (a: any, b: any) => a?.salesPercentage - b?.salesPercentage,
      render: (text: any) => <b>{parseFloat(text).toFixed(2)}%</b>,
    },
  ];

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40, marginTop: 20 }}>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={24} xxl={24} style={{ textAlign: "right" }}>
            <Button type="primary" onClick={handleModalOpen}>
              Add/Update Weekly Data
            </Button>
          </Col>
          <Col xs={24} xl={24} xxl={24} ref={tableRef}>
            <Table
              rowKey={(record) => record?.key}
              loading={loading}
              dataSource={dataSource}
              columns={columns}
              scroll={{ x: "100%" }}
              pagination={false}
              tableLayout="fixed"
              summary={(pageData) => {
                // Toplam değerleri hesaplama
                const totalWeeklyVisitors = pageData.reduce(
                  (sum, record) => sum + record.weeklyVisitors,
                  0
                );
                const totalLeadCount = pageData.reduce(
                  (sum, record) => sum + record.weeklyLeadCount,
                  0
                );
                const totalUnreachableCount = pageData.reduce(
                  (sum, record) => sum + record.unreachableCount,
                  0
                );
                const totalSales = pageData.reduce(
                  (sum, record) => sum + record.totalSales,
                  0
                );
                const totalExpenses = pageData.reduce(
                  (sum, record) => sum + record.totalExpenses,
                  0
                ); // Toplam harcamayı doğrudan topla

                // Ortalama yüzdelik değerler
                const avgUnreachablePercentage =
                  pageData.reduce(
                    (sum, record) => sum + record.unreachablePercentage,
                    0
                  ) / pageData.length;
                const avgSalesPercentage =
                  pageData.reduce(
                    (sum, record) => sum + record.salesPercentage,
                    0
                  ) / pageData.length;

                // Lead Başına Maliyet
                const costPerLead =
                  totalLeadCount > 0 ? totalExpenses / totalLeadCount : 0;

                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row className="summary-cell">
                      <Table.Summary.Cell
                        index={0}
                        colSpan={1}
                        align="center"
                        className="summary-cell"
                      >
                        <b>Total</b>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={1}
                        align="center"
                        className="summary-cell"
                      >
                        <b>{totalWeeklyVisitors}</b>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={2}
                        align="center"
                        className="summary-cell"
                      >
                        <Tooltip title="Average Cost per Lead">
                          <b>${costPerLead.toFixed(2)}*</b>
                        </Tooltip>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={3}
                        align="center"
                        className="summary-cell"
                      >
                        <b>{totalLeadCount}</b>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={4}
                        align="center"
                        className="summary-cell"
                      >
                        <b>{totalUnreachableCount}</b>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={5}
                        align="center"
                        className="summary-cell"
                      >
                        <b>{avgUnreachablePercentage.toFixed(2)}%</b>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={6}
                        align="center"
                        className="summary-cell"
                      >
                        <b>{totalSales}</b>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={7}
                        align="center"
                        className="summary-cell"
                      >
                        <b>${totalExpenses.toFixed(2).toLocaleString()}</b>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={8}
                        align="center"
                        className="summary-cell"
                      >
                        <b>{avgSalesPercentage.toFixed(2)}%</b>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
            />
          </Col>
          <Col xs={24} xl={24} xxl={12}>
            <Title level={4}>Cost Per Lead Chart</Title>
            <ReusableChart
              type="area"
              series={leadCostSeries}
              categories={dateLabels.map((label) => label.label)}
              height={chartHeight}
            />
          </Col>
          <Col xs={24} xl={24} xxl={12}>
            <Title level={4}>Total Expenses Chart</Title>
            <ReusableChart
              type="area"
              series={totalExpensesSeries}
              categories={dateLabels.map((label) => label.label)}
              height={chartHeight}
            />
          </Col>
          <Col xs={24} xl={24} xxl={12}>
            <Title level={4}>Lead Count Chart</Title>
            <ReusableChart
              type="area"
              series={leadCountSeries}
              categories={dateLabels.map((label) => label.label)}
              height={chartHeight}
            />
          </Col>
          <Col xs={24} xl={24} xxl={12}>
            <Title level={4}>Sales Percentage Chart</Title>
            <ReusableChart
              type="area"
              series={salesPercentageSeries}
              categories={dateLabels.map((label) => label.label)}
              height={chartHeight}
            />
          </Col>
        </Row>
      </Col>
      <Modal
        title="Add or Update Weekly Data"
        open={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        width={"35rem"}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFormSubmit}
          initialValues={{ weeklyVisitors: null, totalExpenses: null }}
        >
          <Row gutter={[8, 8]}>
            {/* Tarih Seçimi */}
            <Col xs={24} sm={8}>
              <Form.Item
                name="date"
                label="Select Week"
                rules={[{ required: true, message: "Please select a week!" }]}
              >
                <ReactDatePicker
                  showIcon
                  locale="en-GB"
                  showWeekNumbers
                  showWeekPicker
                  dateFormat="wo 'of' yyyy"
                  selected={selectedWeek}
                  onChange={(date: Date | null) => {
                    handleDateChange(date);
                    form.setFieldsValue({ date });
                  }}
                  placeholderText="Select a week"
                  customInput={
                    <Input
                      placeholder="Select a week"
                      prefix={<CalendarOutlined />}
                    />
                  }
                  popperClassName="custom-react-datepicker"
                />
              </Form.Item>
            </Col>

            {/* İki input yan yana */}
            <Col xs={24} sm={8}>
              <Form.Item
                name="weeklyVisitors"
                label="Weekly Visitors"
                rules={[
                  { required: true, message: "Please enter visitors count!" },
                ]}
              >
                <InputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
            </Col>

            <Col xs={24} sm={8}>
              <Form.Item
                name="totalExpenses"
                label="Total Expenses ($)"
                rules={[
                  { required: true, message: "Please enter total expenses!" },
                ]}
              >
                <InputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Row>
  );
};

export default MarketingTmVisitorAndExpensesReport;
