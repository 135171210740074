import { Col, Row, Table, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { ColumnsType } from "antd/es/table";
import CustomerAssignmentHistoryService from "../../../../services/CustomerAssignmentHistoryService";
import dayjs from "dayjs";
import ReusableChart from "./ReusableChart";

const { Text } = Typography;

const MemberFlagReport = ({
  dateRange,
  memberFlag,
  periodCount,
}: {
  dateRange: any;
  memberFlag: string;
  periodCount: number;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  // eslint-disable-next-line
  const [oldMemberFlagData, setOldMemberFlagData] = useState<any>([]);
  // eslint-disable-next-line
  const [oldMemberFlagByNewMemberFlagData, setOldMemberFlagByNewMemberFlagData] = useState<any>([]);
  // eslint-disable-next-line
  const [newMemberFlagData, setNewMemberFlagData] = useState<any>([]);
  const [groupedReports, setGroupedReports] = useState<any>({});
  const [allKeys, setAllKeys] = useState<Set<string>>(new Set());
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [dateLabels, setDateLabels] = useState<any[]>([]);
  const [chartSeries, setChartSeries] = useState<any[]>([]);
  const [chartHeight, setChartHeight] = useState<number>(200);
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1200 && tableRef.current) {
        const tableColHeight = tableRef.current.getBoundingClientRect().height;
        setChartHeight(Math.max(200, tableColHeight - 30));
      } else {
        setChartHeight(250);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, [dataSource]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1] && memberFlag) {
      const periods = [];
      let start = dayjs(dateRange[0]);
      let end = dayjs(dateRange[1]);

      for (let i = 0; i < periodCount; i++) {
        const formattedStart = start.format("YYYY-MM-DDT00:00:00");
        const formattedEnd = end.format("YYYY-MM-DDT23:59:59");

        periods.push({
          start: formattedStart,
          end: formattedEnd,
          label: `${start.format("DD.MMM.YY")} - ${end.format("DD.MMM.YY")}`,
        });

        // Calculate the previous period
        const diffDays = end.diff(start, "days") + 1;
        end = start.subtract(1, "days");
        start = end.subtract(diffDays - 1, "days");
      }

      const reversedPeriods = periods.reverse(); // Period'ları tersine çevir
      getReports(reversedPeriods, memberFlag);
      setDateLabels(reversedPeriods);
    }
    // eslint-disable-next-line
  }, [dateRange, memberFlag, periodCount]);

  const getReports = async (periods: any[], memberFlag: string) => {
    setLoading(true);

    try {
      const oldMemberFlagPromises = periods.map((period) =>
        CustomerAssignmentHistoryService.getOldMemberFlagReport(
          period.start,
          period.end,
          memberFlag
        )
      );

      const newMemberFlagPromises = periods.map((period) =>
        CustomerAssignmentHistoryService.getNewMemberFlagReport(
          period.start,
          period.end,
          memberFlag
        )
      );

      const oldMemberFlagByNewMemberFlagPromises =
        memberFlag === "SUCCESS_TEAM"
          ? periods.map((period) =>
              CustomerAssignmentHistoryService.getOldMemberFlagReport(
                period.start,
                period.end,
                "NEW"
              )
            )
          : [];

      const [
        oldMemberFlagResponses,
        newMemberFlagResponses,
        oldMemberFlagByNewMemberFlagResponses,
      ] = await Promise.all([
        Promise.all(oldMemberFlagPromises),
        Promise.all(newMemberFlagPromises),
        memberFlag === "SUCCESS_TEAM"
          ? Promise.all(oldMemberFlagByNewMemberFlagPromises)
          : Promise.resolve([]),
      ]);

      setOldMemberFlagData(oldMemberFlagResponses.map((res) => res.data));
      setNewMemberFlagData(
        newMemberFlagResponses.map(
          (res) => res.data.customerAssignmentHistoryList
        )
      );

      // Tüm unique memberFlag anahtarlarını çıkar
      const uniqueKeys = new Set<string>();
      newMemberFlagResponses.forEach((res) => {
        Object.keys(res.data.groupedReport || {}).forEach((key) => {
          uniqueKeys.add(key);
        });
      });

      setAllKeys(uniqueKeys); // allKeys state'ini güncelle

      // Eksik olanları doldur
      const filledGroupedReports = newMemberFlagResponses.map((res) => {
        const groupedReport = res.data.groupedReport || {};
        const filledReport: Record<string, number> = {};
        uniqueKeys.forEach((key) => {
          filledReport[key] = groupedReport[key] || 0;
        });
        return filledReport;
      });

      setGroupedReports(filledGroupedReports);

      if (memberFlag === "SUCCESS_TEAM") {
        setOldMemberFlagByNewMemberFlagData(
          oldMemberFlagByNewMemberFlagResponses.map((res) => res.data)
        );
      }

      const newDataSource = [
        {
          key: "1",
          ...periods.reduce((acc, period, index) => {
            acc[`joiningCount_${index}`] =
              newMemberFlagResponses[index]?.data.customerAssignmentHistoryList
                .length || 0;
            acc[`leavingCount_${index}`] =
              oldMemberFlagResponses[index]?.data.length || 0;

            if (memberFlag === "SUCCESS_TEAM") {
              acc[`newCount_${index}`] =
                oldMemberFlagByNewMemberFlagResponses[index]?.data.length || 0;
            }
            return acc;
          }, {}),
        },
      ];
      setDataSource(newDataSource);

      const joiningSeries = periods.map(
        (_, index) =>
          newMemberFlagResponses[index]?.data.customerAssignmentHistoryList
            .length || 0
      );
      const leavingSeries = periods.map(
        (_, index) => oldMemberFlagResponses[index]?.data.length || 0
      );

      const newSeries =
        memberFlag === "SUCCESS_TEAM"
          ? periods.map(
              (_, index) =>
                oldMemberFlagByNewMemberFlagResponses[index]?.data.length || 0
            )
          : [];

      setChartSeries([
        { name: "Joined Customers", data: joiningSeries },
        { name: "Left Customers", data: leavingSeries },
        ...(memberFlag === "SUCCESS_TEAM"
          ? [{ name: "New Customers", data: newSeries }]
          : []),
      ]);
      // setDateLabels([...periods].reverse());
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: (
        <div
          className="column-group-title"
          style={{
            border: "3px solid #e9e9e9",
            paddingBottom: "8px",
            paddingTop: "8px",
            marginBottom: "-16px",
          }}
        >
          Joined Customers Count
        </div>
      ),
      align: "center",
      children: dateLabels.map((label: any, index: number) => ({
        title: label.label,
        dataIndex: `joiningCount_${index}`,
        align: "center",
        width: 175,
        render: (text: any) => <b>{text}</b>,
      })),
    },
    {
      title: (
        <div
          className="column-group-title"
          style={{
            border: "3px solid #e9e9e9",
            paddingBottom: "8px",
            paddingTop: "8px",
            marginBottom: "-16px",
          }}
        >
          Left Customers Count
        </div>
      ),
      align: "center",
      children: dateLabels.map((label: any, index: number) => ({
        title: label.label,
        dataIndex: `leavingCount_${index}`,
        align: "center",
        width: 175,
        render: (text: any) => <b>{text}</b>,
      })),
    },
    ...(memberFlag === "SUCCESS_TEAM"
      ? [
          {
            title: (
              <div
                className="column-group-title"
                style={{
                  border: "3px solid #e9e9e9",
                  paddingBottom: "8px",
                  paddingTop: "8px",
                  marginBottom: "-16px",
                }}
              >
                New Customers Count
              </div>
            ),
            align: "center" as "center",
            children: dateLabels.map((label: any, index: number) => ({
              title: label.label,
              dataIndex: `newCount_${index}`,
              align: "center" as "center",
              width: 175,
              render: (text: any) => <b>{text}</b>,
            })),
          },
        ]
      : []),
  ];

  const expandedRowRender = () => {
    const groupedColumns: ColumnsType<any> = [
      {
        title: "Transferred From",
        dataIndex: "memberFlag",
        key: "memberFlag",
        width: 55,
        ellipsis: true,
        fixed: "left",
        sorter: (a: any, b: any) => a.memberFlag.localeCompare(b.memberFlag),
        defaultSortOrder: "descend",
        render: (text: any) => {
          const formattedText = text
            .toLowerCase()
            .split("_")
            .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");

          return (
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              title={formattedText}
            >
              <b>{formattedText}</b>
            </div>
          );
        },
      },
      ...dateLabels.map((label: any, index: number) => ({
        title: label.label,
        dataIndex: `count_${index}`,
        key: `count_${index}`,
        align: "center" as "center",
        width: 100,
        render: (count: any) => <b>{count ?? 0}</b>,
      })),
    ];

    return (
      <Table
        columns={groupedColumns}
        dataSource={Array.from(allKeys).map((key) => {
          const data = { memberFlag: key } as {
            memberFlag: string;
            [key: string]: any;
          };
          dateLabels.forEach((_, index: number) => {
            data[`count_${index}`] = groupedReports[index]?.[key] || 0;
          });
          return data;
        })}
        pagination={false}
        rowKey="memberFlag"
        size="small"
      />
    );
  };

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40, marginTop: 40 }}>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={14}>
            <ReusableChart
              type="bar"
              series={chartSeries}
              categories={dateLabels.map((label) => label.label)}
              height={chartHeight}
            />
          </Col>
          <Col xs={24} xl={10} ref={tableRef}>
            <Text style={{ margin: "0 0 5px 0" }} type="warning">
              ** Note: The data displayed in the expanded table includes only
              customers who have been transferred from other locations.
            </Text>
            <Table
              rowKey={(record) => record?.key}
              loading={loading}
              dataSource={dataSource}
              columns={columns}
              scroll={{ x: "100%" }}
              pagination={false}
              expandable={{
                expandedRowRender,
              }}
              tableLayout="fixed"
              className="summary-report-table"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default MemberFlagReport;
