import { AxiosResponse } from "axios";
import http from "../http-common";
import { IUserResponseData, IUserRequestData } from "../types/User";

const getAll = (
  filter: string = "",
  onlyActive: boolean = true
): Promise<IUserResponseData[]> => {
  if (filter === "?roleName=ACCOUNT_MANAGER") {
    return Promise.all([
      http.get<Array<IUserResponseData>>(
        `/users?roleName=ACCOUNT_MANAGER&active=${onlyActive}`
      ),
      http.get<Array<IUserResponseData>>(
        `/users?roleName=ACCOUNT_MANAGER_MANAGER&active=${onlyActive}`
      ),
    ]).then((responses: AxiosResponse<IUserResponseData[]>[]) => {
      return responses.flatMap((response) => response.data);
    });
  } else if (filter === "?roleName=HI5_ACM") {
    return Promise.all([
      http.get<Array<IUserResponseData>>(
        `/users?roleName=HI5_ACM_MEMBER&active=${onlyActive}`
      ),
      http.get<Array<IUserResponseData>>(
        `/users?roleName=HI5_ACM_MANAGER&active=${onlyActive}`
      ),
    ]).then((responses: AxiosResponse<IUserResponseData[]>[]) => {
      const allUsers = responses.flatMap((response) => response.data);
      return allUsers.filter(user => user.username !== "duygu.karakilic.hi5acm.crm@oneamz.com" && user.username !== "murat.yakar.hi5acm.crm@oneamz.com");
    });
  } else if (filter === "?roleName=SUCCESS_TEAM") {
    return Promise.all([
      http.get<Array<IUserResponseData>>(
        `/users?roleName=SUCCESS_TEAM&active=${onlyActive}`
      ),
      http.get<Array<IUserResponseData>>(
        `/users?roleName=SUCCESS_TEAM_MANAGER&active=${onlyActive}`
      ),
    ]).then((responses: AxiosResponse<IUserResponseData[]>[]) => {
      return responses.flatMap((response) => response.data);
    });
  } else if (filter === "?roleName=SUCCESS_TEAM_REPORTER") {
    return Promise.all([
      http.get<Array<IUserResponseData>>(
        `/users?roleName=SUCCESS_TEAM_REPORTER&active=${onlyActive}`
      ),
    ]).then((responses: AxiosResponse<IUserResponseData[]>[]) => {
      return responses.flatMap((response) => response.data);
    });
  } else if (filter === "?roleName=SUSPEND_TEAM") {
    return Promise.all([
      http.get<Array<IUserResponseData>>(
        `/users?roleName=SUSPEND_TEAM&active=${onlyActive}`
      ),
      http.get<Array<IUserResponseData>>(
        `/users?roleName=SUSPEND_TEAM_MANAGER&active=${onlyActive}`
      ),
    ]).then((responses: AxiosResponse<IUserResponseData[]>[]) => {
      return responses.flatMap((response) => response.data);
    });
  } else if (filter === "?roleName=CALL_TEAM") {
    return Promise.all([
      http.get<Array<IUserResponseData>>(
        `/users?roleName=CALL_TEAM&active=${onlyActive}`
      ),
      http.get<Array<IUserResponseData>>(
        `/users?roleName=CALL_TEAM_MANAGER&active=${onlyActive}`
      ),
    ]).then((responses: AxiosResponse<IUserResponseData[]>[]) => {
      return responses.flatMap((response) => response.data);
    });
  } else if (filter === "?roleName=MAS_TEAM") {
    return Promise.all([
      http.get<Array<IUserResponseData>>(
        `/users?roleName=MAS_TEAM_MEMBER&active=${onlyActive}`
      ),
      http.get<Array<IUserResponseData>>(
        `/users?roleName=MAS_TEAM_MANAGER&active=${onlyActive}`
      ),
    ]).then((responses: AxiosResponse<IUserResponseData[]>[]) => {
      return responses.flatMap((response) => response.data);
    });
  } else if (filter === "?roleName=ACCOUNT_MANAGEMENT_TEAM") {
    return Promise.all([
      http.get<Array<IUserResponseData>>(
        `/users?roleName=ACCOUNT_MANAGEMENT_MEMBER&active=${onlyActive}`
      ),
      http.get<Array<IUserResponseData>>(
        `/users?roleName=ACCOUNT_MANAGEMENT_MANAGER&active=${onlyActive}`
      ),
    ]).then((responses: AxiosResponse<IUserResponseData[]>[]) => {
      return responses.flatMap((response) => response.data);
    });
  }else if (filter === "?roleName=ADMIN") {
    return Promise.all([http.get<Array<IUserResponseData>>(`/users`)]).then(
      (responses: AxiosResponse<IUserResponseData[]>[]) => {
        return responses.flatMap((response) => response.data);
      }
    );
  } else {
    return http
      .get<Array<IUserResponseData>>(`/users?${filter}&active=${onlyActive}`)
      .then((response) => response.data);
  }
};

const getAllWithoutActiveFilter = (
  filter: string = "",
): Promise<IUserResponseData[]> => {
  if (filter === "?roleName=ACCOUNT_MANAGER") {
    return Promise.all([
      http.get<Array<IUserResponseData>>(
        `/users?roleName=ACCOUNT_MANAGER`
      ),
      http.get<Array<IUserResponseData>>(
        `/users?roleName=ACCOUNT_MANAGER_MANAGER`
      ),
    ]).then((responses: AxiosResponse<IUserResponseData[]>[]) => {
      return responses.flatMap((response) => response.data);
    });
  } else if (filter === "?roleName=HI5_ACM") {
    return Promise.all([
      http.get<Array<IUserResponseData>>(
        `/users?roleName=HI5_ACM_MEMBER`
      ),
      http.get<Array<IUserResponseData>>(
        `/users?roleName=HI5_ACM_MANAGER`
      ),
    ]).then((responses: AxiosResponse<IUserResponseData[]>[]) => {
      return responses.flatMap((response) => response.data);
    });
  } else if (filter === "?roleName=SUCCESS_TEAM") {
    return Promise.all([
      http.get<Array<IUserResponseData>>(
        `/users?roleName=SUCCESS_TEAM`
      ),
      http.get<Array<IUserResponseData>>(
        `/users?roleName=SUCCESS_TEAM_MANAGER`
      ),
    ]).then((responses: AxiosResponse<IUserResponseData[]>[]) => {
      return responses.flatMap((response) => response.data);
    });
  } else if (filter === "?roleName=SUSPEND_TEAM") {
    return Promise.all([
      http.get<Array<IUserResponseData>>(
        `/users?roleName=SUSPEND_TEAM`
      ),
      http.get<Array<IUserResponseData>>(
        `/users?roleName=SUSPEND_TEAM_MANAGER`
      ),
    ]).then((responses: AxiosResponse<IUserResponseData[]>[]) => {
      return responses.flatMap((response) => response.data);
    });
  } else if (filter === "?roleName=CALL_TEAM") {
    return Promise.all([
      http.get<Array<IUserResponseData>>(
        `/users?roleName=CALL_TEAM`
      ),
      http.get<Array<IUserResponseData>>(
        `/users?roleName=CALL_TEAM_MANAGER`
      ),
    ]).then((responses: AxiosResponse<IUserResponseData[]>[]) => {
      return responses.flatMap((response) => response.data);
    });
  } else if (filter === "?roleName=MAS_TEAM") {
    return Promise.all([
      http.get<Array<IUserResponseData>>(
        `/users?roleName=MAS_TEAM_MEMBER`
      ),
      http.get<Array<IUserResponseData>>(
        `/users?roleName=MAS_TEAM_MANAGER`
      ),
    ]).then((responses: AxiosResponse<IUserResponseData[]>[]) => {
      return responses.flatMap((response) => response.data);
    });
  } else if (filter === "?roleName=ACCOUNT_MANAGEMENT_TEAM") {
    return Promise.all([
      http.get<Array<IUserResponseData>>(
        `/users?roleName=ACCOUNT_MANAGEMENT_MEMBER`
      ),
      http.get<Array<IUserResponseData>>(
        `/users?roleName=ACCOUNT_MANAGEMENT`
      ),
    ]).then((responses: AxiosResponse<IUserResponseData[]>[]) => {
      return responses.flatMap((response) => response.data);
    });
  } else if (filter === "?roleName=ADMIN") {
    return Promise.all([http.get<Array<IUserResponseData>>(`/users`)]).then(
      (responses: AxiosResponse<IUserResponseData[]>[]) => {
        return responses.flatMap((response) => response.data);
      }
    );
  } else {
    return http
      .get<Array<IUserResponseData>>(`/users?${filter}`)
      .then((response) => response.data);
  }
};

const getAllWithPagination = (
  filter: string | null = "",
  roleName: string | null = "",
): Promise<any[]> => { // Adjust the return type to match the structure of your data
  const filterParam = filter || "";
  const roleNameParam = roleName || "";
  // const roleNameValues = ['SUCCESS_TEAM', 'SUCCESS_TEAM_MANAGER'];
  // const encodedRoleNames = roleNameValues.map(role => encodeURIComponent(role)).join(',');
  if (roleNameParam === "ACCOUNT_MANAGER_MANAGER") {
    return Promise.all([
      http.get(`/users/with-pagination?${filterParam}&roleName=ACCOUNT_MANAGER`),
      // http.get(`/users/with-pagination?${filterParam}&roleName=ACCOUNT_MANAGER_MANAGER`),
    ]).then((responses: AxiosResponse<any>[]) => {
      return responses.flatMap((response) => response.data);
    });
  } else if (roleNameParam === "HI5_ACM_MANAGER") {
    return Promise.all([
      http.get(`/users/with-pagination?${filterParam}&roleName=HI5_ACM_MEMBER`),
      // http.get(`/users/with-pagination?${filterParam}&roleName=HI5_ACM_MANAGER`),
    ]).then((responses: AxiosResponse<any>[]) => {
      return responses.flatMap((response) => response.data);
    });
  }else if (roleNameParam === "SUCCESS_TEAM_MANAGER") {
    return Promise.all([
      http.get(`/users/with-pagination?${filterParam}&roleName=SUCCESS_TEAM`),
      // http.get(`/users/with-pagination?${filterParam}&roleName=SUCCESS_TEAM_MANAGER`),
    ]).then((responses: AxiosResponse<any>[]) => {
      return responses.flatMap((response) => response.data);
    });
  } else if (roleNameParam === "SUSPEND_TEAM_MANAGER") {
    return Promise.all([
      http.get(`/users/with-pagination?${filterParam}&roleName=SUSPEND_TEAM`),
      // http.get(`/users/with-pagination?${filterParam}&roleName=SUSPEND_TEAM_MANAGER`),
    ]).then((responses: AxiosResponse<any>[]) => {
      return responses.flatMap((response) => response.data);
    });
  } else if (roleNameParam === "CALL_TEAM_MANAGER") {
    return Promise.all([
      http.get(`/users/with-pagination?${filterParam}&roleName=CALL_TEAM`),
      // http.get(`/users/with-pagination?${filterParam}&roleName=CALL_TEAM_MANAGER`),
    ]).then((responses: AxiosResponse<any>[]) => {
      return responses.flatMap((response) => response.data);
    });
  } else if (roleNameParam === "MAS_TEAM_MANAGER") {
    return Promise.all([
      http.get(`/users/with-pagination?${filterParam}&roleName=MAS_TEAM_MEMBER`),
      // http.get(`/users/with-pagination?${filterParam}&roleName=MAS_TEAM_MANAGER`),
    ]).then((responses: AxiosResponse<any>[]) => {
      return responses.flatMap((response) => response.data);
    });
  } else if (roleNameParam === "ACCOUNT_MANAGEMENT") {
    return Promise.all([
      http.get(`/users/with-pagination?${filterParam}&roleName=ACCOUNT_MANAGEMENT_MEMBER`),
      // http.get(`/users/with-pagination?${filterParam}&roleName=ACCOUNT_MANAGEMENT`),
    ]).then((responses: AxiosResponse<any>[]) => {
      return responses.flatMap((response) => response.data);
    });
  } else if (roleNameParam === "ADMIN") {
    return Promise.all([
      http.get(`/users/with-pagination?${filterParam}`),
      // http.get(`/users/with-pagination?${filterParam}`),
    ]).then((responses: AxiosResponse<any>[]) => {
      return responses.flatMap((response) => response.data);
    });
  } else {
    return Promise.all([
      http.get(`/users/with-pagination?${filterParam}`),
      // http.get(`/users/with-pagination?${filterParam}`),
    ]).then((responses: AxiosResponse<any>[]) => {
      return responses.flatMap((response) => response.data);
    });
  }
};


const get = (id: any) => {
  return http.get<IUserResponseData>(`/users/${id}`);
};

const getMe = () => {
  return http.get<IUserResponseData>(`/users/me`);
};

const getMeRoles = () => {
  return http.get<any>(`/users/me/roles`);
}

const updateMeSelectedRole = (selectedRole: { selectedRole: string }) => {
  return http.put<any>(`/users/selected-role`, selectedRole);
};


// const getMeNotification = () => {
//   return http.get<IUserResponseData>(`/users/me/notifications`);
// };

// const readNotification = (id: number) => {
//   return http.put<any>(`/notifications/${id}/read`);
// };

// const create = (data: IUserRequestData) => {
//   return http.post<IUserRequestData>("/users", data);
// };

const updateRoleByUserId = (id: number, data: any) => {
  return http.put<any>(`/users/${id}/roles`, data);
};

const update = (data: IUserRequestData) => {
  return http.put<any>(`/users/me`, data);
};

const updateSalesTargetByUserId = (id: number, data: any) => {
  return http.put<any>(`/users/${id}/sales-target`, data);
};

// @ts-ignore
const updateStatusUserById = (data) => {
  return http.put<any>(`/users/status`, data);
};

const updateAutoAssignmentUserById = (data : any) => {
  return http.put<any>(`/users/auto-assignment`, data);
};

// const remove = (id: any) => {
//   return http.delete<any>(`/users/${id}`);
// };

const UserService = {
  getAll,
  getAllWithoutActiveFilter,
  getAllWithPagination,
  get,
  getMe,
  getMeRoles,
  updateMeSelectedRole,
  updateRoleByUserId,
  // getMeNotification,
  // readNotification,
  // create,
  update,
  updateSalesTargetByUserId,
  updateStatusUserById,
  updateAutoAssignmentUserById
  // remove,
};

export default UserService;
